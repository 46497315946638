import React, { useRef, useState, createRef, useEffect, useLayoutEffect } from "react";
import { Card, Button, CardBody, Col, Container, Nav, NavItem, NavLink, TabContent, TabPane, CardText, UncontrolledTooltip, Form, FormGroup, Input, InputGroup, Label, Row, FormFeedback, CardFooter, Modal, ModalBody, CardTitle, CardHeader } from "reactstrap";
import { useFormik } from "formik";
import "../../../node_modules/@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import "./ques.css"

import * as Yup from "yup";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import classnames from "classnames";

import * as url from "../../../src/helpers/url_helper";
import * as constants from "constants/layout"
import { logoutUser } from "store/auth/login/actions";
import { getLoggedInUser, getRefreshToken } from "../../../src/helpers/fakebackend_helper";
import { getmasterlist, getqamasterlist } from "../../store/actions";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link, useNavigate } from 'react-router-dom';
import Spinners from "../../components/Common/Spinner";

import ApproveModal from "components/Common/ApproveModal";
import SubmitConfirmModal from "components/Common/SubmitConfirmModal";
import RegSubmitConfirmModal from "components/Common/RegSubmitConfirmModal";
import { options } from "@fullcalendar/core/preact";

const AddQuestions = () => {
    const user = getLoggedInUser()
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const store = useSelector(state => state.ExamBoardCategory)
    const [engOptionValues, setEngOptionValues] = useState(new Array(5).fill(''));
    const [regOptionValues, setRegOptionValues] = useState(new Array(5).fill(''));
    var isClone = localStorage.getItem('isClone');
    const [isLoading, setLoading] = useState(false)
    const [editorContent, setEditorContent] = useState('');
    const [statusQues, setstatusQues] = useState(true);
    const [approvebutton, setApprovebutton] = useState(false);
    const [rejectbutton, setRejectbutton] = useState(false);
    const [shuffle, setShuffle] = useState(false);
    const [approveQues, setapproveQues] = useState(true);
    const [qnError, setQnError] = useState(false);
    const [optionError, setOptionError] = useState(false);
    const [ansError, setAnsError] = useState(false);
    const [languageActiveTab, setlanguageActiveTab] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState([]);
    const [disabledsubcat, setDisabledsubcat] = useState(false);
    const [disabledtopic, setDisabledTopic] = useState(false);

    const [successFlag, setSuccessFlag] = useState(false);

    const [engQnTxt, setEngQnTxt] = useState("");
    const [notes, setNotes] = useState("");
    const [reqnotes, setReqNotes] = useState("");
    const [regQnTxt, setRegQnTxt] = useState("");
    const [regNotesTxt, setRegNotesTxt] = useState("");
    const [qnObj, setQnObj] = useState(null);
    const engOptionsRef = useRef(engOptionValues.map(() => createRef()));
    const regOptionsRef = useRef(regOptionValues.map(() => createRef()));
    const { qid } = useParams();
    const [saveClone, setSaveClone] = useState(0);
    const [langArr, setLangArr] = useState([])
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [confirmationFrom, setConfirmationFrom] = useState('');
    const [confirmationType, setConfirmationType] = useState('');
    const [confirmationOptIndex, setConfirmationOptIndex] = useState('')
    const [generalsetting, setGeneralSetting] = useState("");
    const [showEngDeleteIcon, setShowEngDeleteIcon] = useState(false)
    const [showRegDeleteIcon, setShowRegDeleteIcon] = useState(false)
    const [saveDisable, setSaveDisable] = useState(0)
    const [approvalFlag, setapprovalFlag] = useState(0)

    const [engOptionCount, setEngOptionCount] = useState(true)
    const [regOptionCount, setRegOptionCount] = useState(true)
    const [editLoadQaData, setEditLoadQAData] = useState([])

    const [engQAIDArr, setEngQAIDArr] = useState([])
    const [regQAIDArr, setRegQAIDArr] = useState([])
    const [optionMasterAllLanguageData, setOptionMasterAllLanguageData] = useState([]);
    const [optionMasterLanguageData, setOptionMasterLanguageData] = useState([]);
    const [userBasedApproveList, setUserBasedApproveList] = useState([]);
    const [englishBasedTab, setEnglishBasedTab] = useState(0);
    const [regionalBasedTab, setRegionalBasedTab] = useState(0);
    const [submitForApprovalHandling, setSubmitForApprovalHandling] = useState(0);
    const [submitForApprovalHandlingReg, setSubmitForApprovalHandlingReg] = useState(0);
    const [multiLanguageBasedTab, setMultiLanguageBasedTab] = useState(0);
    const [englishBasedDisable, setEnglishBasedDisable] = useState(0);
    const [regionalBasedDisable, setRegionalBasedDisable] = useState(0);

    const [approveModal, setApproveModal] = useState(false);
    const [rejectModal, setRejectModal] = useState(false);
    const [recordId, setRecordId] = useState(false);
    const [qaListStatus, setQaListStatus] = useState(false);
    const [qaApprovalButton, setQaApprovalButton] = useState(0);
    const [qaApprovalSubmitButton, setQaApprovalsubmitButton] = useState(0);

    const [qaList, setQAList] = useState([]);
    const [approveStatusArray, setApproveStatusArray] = useState([]);
    const [approveLang, setApproveLang] = useState(0);
    const [rejectLang, setRejectLang] = useState(0);

    const [userLangArray,setUserLangArray] = useState([])
    const [submitConfirmModal, setSubmitConfirmModal] = useState(false);
    const [regSubmitConfirmModal, setRegSubmitConfirmModal] = useState(false);


    
    const [optionBoardName, setOptionBoardName] = useState([]);


    const [optionSubjectName, setOptionSubjectName] = useState([]);
    const [optionSubjectSubcatName, setOptionSubjectSubcatName] = useState([]);
    const [optionExamType, setOptionExamType] = useState([]);
    const [optionExamLevel, setOptionExamLevel] = useState([]);
    const [optionTopicName, setOptionTopicName] = useState([]);
    const [optionExamPaper, setOptionExamPaper] = useState([]);
    const [configurationOptionList, setConfigurationOptionList] = useState([]);
    const [optionAllSubjectName, setOptionAllSubjectName] = useState([]);
    const [optionAllSubjectSubcatName, setOptionAllSubjectSubcatName] = useState([]);
    const [optionAllTopicName, setOptionAllTopicName] = useState([]);
    const [optionAllExamPaper, setOptionAllExamPaper] = useState([]);

    const [selectedBoardName, setselectedBoardName] = useState(null);
    const [selectedTopicName, setselectedTopicName] = useState(null);
    const [selectedSubjectName, setselectedSubjectName] = useState(null);
    const [selectedSubjectSubcatName, setselectedSubjectSubcatName] = useState(null);
    const [selectedExamType, setselectedExamType] = useState(null);
    const [selectedExamLevel, setselectedExamLevel] = useState(null);
    const [selectedExamPaper, setselectedExamPaper] = useState(null);

    const [inputFocus, setInputFocus] = useState(false);

    const [checkedInputToggle, setCheckedInputToggle] = useState({
        "0": false,
        "1": false,
        "2": false,
        "3": false,
        "4": false
    });
    const [checkedRegInputToggle, setCheckedRegInputToggle] = useState({
        "0": false,
        "1": false,
        "2": false,
        "3": false,
        "4": false
    });
    const [checkedAnswerToggle, setCheckedAnswerToggle] = useState({});
    const [checkedRegAnswerToggle, setCheckedRegAnswerToggle] = useState({});



    const engQnIframeRef = useRef(null);
    const regQnIframeRef = useRef(null);
    const regNotesIframeRef = useRef(null);
    const enNotesIframeRef = useRef(null);


    const [checkedEngQnEditorEble, setCheckedEngQnEditorEble] = useState(false)
    const [checkedRegQnEditorEble, setCheckedRegQnEditorEble] = useState(false)
    const [checkedRegNotesEditorEble, setCheckedRegNotesEditorEble] = useState(false)
    const [checkedEngNotesEditorEble, setCheckedEngNotesEditorEble] = useState(false)

 

    const [engReason, setEngReason] = useState('');
    
    const [regReason, setRegReason] = useState('');
    const [reason, setReason] = useState('');


    const [editorEngQnTxt, setEditorEngQnTxt] = useState("")
    const [editorRegQnTxt, setEditorRegQnTxt] = useState("")
    const [editorEngNotesTxt, setEditorEngNotesTxt] = useState("")
    const [editorRegNotesTxt, setEditorRegNotesTxt] = useState("")

    const [initialeditorEngQnTxt, setInitialEditorEngQnTxt] = useState("")
    const [initialeditorRegQnTxt, setInitialEditorRegQnTxt] = useState("")
    const [initialeditorEngNotesTxt, setInitialEditorEngNotesTxt] = useState("")
    const [initialeditorRegNotesTxt, setInitialEditorRegNotesTxt] = useState("")

    const editorEngQnInstance = useRef(null);
    const editorRegQnInstance = useRef(null);
    const editorEngNotesInstance = useRef(null);
    const editorRegNotesInstance = useRef(null);

    const [editorEditloadEngOptions, setEditorEditloadEngOptions] =   useState(
        Array.from({ length: 5 }, () => ({ qa_name: "" }))
    );
    const [editorEditloadRegOptions, setEditorEditloadRegOptions] =   useState(
        Array.from({ length: 5 }, () => ({ qa_name: "" }))
    );
    
    const [initialEditorEditloadEngOptions, setInitialEditorEditloadEngOptions] =   useState(
        Array.from({ length: 5 }, () => ({ qa_name: "" }))
    );
    const [initialEditorEditloadRegOptions, setInitialEditorEditloadRegOptions] =    useState(
        Array.from({ length: 5 }, () => ({ qa_name: "" }))
    );




    const editorengOptionsInstance = useRef(engOptionValues.map(() => createRef()));
    const editorregOptionsInstance = useRef(regOptionValues.map(() => createRef()));
    const handleSubmitApprove = async (approval) => {

        // var data;
        setQaApprovalButton(1)
        // if (qid != 0) {

        //     if (englishBasedTab && regionalBasedTab) {
        //         data = {
        //             approval_flag: 3,
        //             qt_langflag: approval,
        //             qid: Number(qid),
        //         }

        //         // } else if (englishBasedTab) {
        //         //     console.log('eng')

        //         // } else if (regionalBasedTab) {
        //         //     console.log('reg')


        //     } else if {
        //         data = {
        //             approval_flag: 3,
        //             qt_langflag: approval,
        //             qid: Number(qid),
        //         }

        //     }else if{
        //         data = {
        //             approval_flag: 3,
        //             qt_langflag: approval,
        //             qid: Number(qid),
        //         }

        //     }        
        //     else {
        //         console.log('--=-=')
        //         data = {
        //             approval_flag: 3,
        //             qt_langflag: approval,
        //             qid: Number(qid),
        //         }

        //     }

        //     const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
        //     const response1 = await axios.post(url.BASE_URL + 'QA/approve', data, config)
        //     if (response1 && response1.data && response1.data && response1.data.status === 200) {
        //         toast.success(response1.data.body.message, { autoClose: 2000 });
        //     }


        //     if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
        //         const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
        //         if (refresh) {
        //             if (Number(refresh) === constants.invalidRefreshToken) {
        //                 logoutUser()
        //             } else {
        //                 await getmasterdatalistapi(qid) // calling same function again getting refresh token
        //             }
        //         }
        //     }

        // } else {
        //     const data = {
        //         "approval_flag": 3,
        //         "qt_approval_flag": approval
        //     }
        //     handleSaveClick(data)
        // }



        // console.log('englis', englishBasedTab, regionalBasedTab)
        // console.log('======', editLoadQaData)
        // const approve_status_array = editLoadQaData.map(item => item.qt_approve_status);
        // console.log('approbe', approve_status_array)

        // if (qid != 0) {
            setapprovalFlag(1)
            const data = {
                "approval_lang": approval,
                "qt_approval_flag": 3
            }
            handleSaveClick(data)

        // } else {
        //     const data = {
        //         "approval_flag": 3,
        //         "qt_approval_flag": approval
        //     }
        //     setapprovalFlag(1)
        //     handleSaveClick(data)
        // }



    }
    // const [approvecheck, setApproveCheck] = useState(true)
    const customStyles = {
        option: (styles, state, data) => { // fixed
            return {
                ...styles,
                backgroundColor: state.isDisabled
                    ? undefined
                    : state.isSelected
                        ? '#a6b0cf57'
                        : state.isFocused
                            ? '#556ee6'
                            : 'white',

                color: state.isDisabled
                    ? '#ccc'
                    : state.isSelected
                        ? 'black'
                        : state.isFocused
                            ? 'white'
                            : 'black',

                cursor: state.isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !state.isDisabled
                        ? state.isSelected
                            ? '#556ee6'
                            : '#556ee6'
                        : undefined,
                },
                fontWeight: state.value && Number(state.value.split('-')[1]) !== 0 ? '' : '600'
            };
        }
    };
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            // selectedBoardName: (qnObj && qnObj.boardname) || undefined,
            selectedSubjectName: (qnObj && qnObj.subject) || undefined,
            selectedTopicName: (qnObj && qnObj.topicname) || undefined,
            selectedExamLevel: (qnObj && qnObj.examlevel) || undefined,
            selectedExamPaper: (qnObj && qnObj.examPaper) || undefined,

        },
        validationSchema: Yup.object({
            //   subjectID: Yup.string()
            //     .matches(
            //       /[0-9\.\-\s+\/()]+/,
            //       "Please Enter Valid Order Id"
            //     ).required("Please Enter Your Order Id"),
            // selectedBoardName: Yup.object().required("Please Select Board"),
            selectedSubjectName: Yup.object().required("Please Select Subject Name"),
            // selectedTopicName: Yup.object().required("Please Select Topic Name"),
            selectedExamLevel: Yup.object().required("Please Select Exam Level"),
            selectedExamPaper: Yup.object().required("Please Select Exam Paper"),


        }),
        onSubmit: (values) => {

            const data = {
                "approval_flag": 0,
                qt_approval_flag:0
            }
            handleSaveClick(data);
        },
    });


    const getmasterlistapi = () => {

        dispatch(getqamasterlist({ user_id: Number(user.uid) }));
    }



    useEffect(() => {

         getmasterlistapi()
        document.getElementById('headerTitle').innerHTML = 'Questions';
        getmasterdatalistapi();
    }, []);

    useEffect(() => {

        const data = userBasedApproveList[0]?.u_langflag
        if (data?.length == 1) {
            if (data.includes('1')) {
                setEnglishBasedTab(1)
                setRegionalBasedTab(0)
                setMultiLanguageBasedTab(0)
                setEnglishBasedDisable(1)

            } else if (data.includes('2')) {
                setRegionalBasedTab(1)
                setEnglishBasedTab(0)
                setMultiLanguageBasedTab(0)
                setRegionalBasedDisable(1)

            } else if (data.includes('1,2')) {
                setRegionalBasedTab(1)
                setEnglishBasedTab(1)
                setMultiLanguageBasedTab(1)
            }
        } else if (data?.length > 1) {

            setRegionalBasedTab(1)
            setEnglishBasedTab(1)
            setMultiLanguageBasedTab(0)
        }
    }, [userBasedApproveList, submitForApprovalHandling, qaApprovalSubmitButton])

    // useEffect(() => {
    //     console.log('regionalBasedDisable', regionalBasedDisable)
    //     console.log('englishBasedDisable', englishBasedDisable)
    //     console.log('englishBasedTab', englishBasedTab)
    //     console.log('regionalBasedTab', regionalBasedTab)
    //     console.log('multiLanguageBasedTab', multiLanguageBasedTab)

    // }, [regionalBasedDisable, englishBasedDisable, englishBasedTab, regionalBasedTab, multiLanguageBasedTab])




    const getmasterdatalistapi = async () => {
     
        // setLoading(true)
        // dispatch(getmasterlist({ user_id: Number(user.uid) }));
        const data = { user_id: Number(user.uid) }
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
        const response1 = await axios.post(url.BASE_URL + 'master/getMaster', data, config)
        if (response1 && response1.data && response1.data && response1.data.status === 200) {
            
            // setMasterData(response1?.data?.body)

            setOptionBoardName(response1?.data?.body?.board_categoryList)
            setOptionAllSubjectName(response1?.data?.body?.subjectList)
            setOptionSubjectName([])
            setOptionAllSubjectSubcatName(response1?.data?.body?.subCategryList)
            setOptionSubjectSubcatName([])
            setOptionAllExamPaper(response1?.data?.body?.examPaperList)
            setOptionExamPaper(response1?.data?.body?.examPaperList)
            setOptionAllTopicName(response1?.data?.body?.topicList)
            setOptionTopicName([])
            setOptionMasterAllLanguageData(response1?.data?.body?.languageList)
            setOptionMasterLanguageData(response1?.data?.body?.languageList)


            // if (store.masterlist && store.masterlist.board_categoryList) {
            //         var boardList = store.masterlist.board_categoryList.filter(e => e.c_exctid != 0)
            //         setOptionBoardName(boardList)
            //     }

            //     if (store.masterlist && store.masterlist.conf_subject_subcategoryList) {
            //         setOptionAllSubjectName(store.masterlist.conf_subject_subcategoryList)
            //         setOptionSubjectName(store.masterlist.conf_subject_subcategoryList)
            //     }

            //     if (store.masterlist && store.masterlist.examPaperList) {
            //         setOptionAllExamPaper(store.masterlist.examPaperList)
            //         setOptionExamPaper(store.masterlist.examPaperList)
            //     }
            //     if (store.masterlist && store.masterlist.topicList) {
            //         setOptionAllTopicName(store.masterlist.topicList)
            //         setOptionTopicName(store.masterlist.topicList)
            //     }
            // }
            if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
                const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        logoutUser()
                    } else {
                        await getmasterdatalistapi(qid) // calling same function again getting refresh token
                    }
                }
            }
        }
    }
    const onClickDelete = (indexToRemove, lang_id) => {



        //  return false
        if (lang_id == "1") {
            if (checkedInputToggle[indexToRemove] == true)
                checkedInputToggle[indexToRemove] = false
            // Remove the element at the specified index
            var options_removed = engOptionValues.splice(indexToRemove, 1);
            // To remove trailing empty strings, you can use a loop or filter
            options_removed = options_removed.filter(value => value !== '');
            if (engOptionValues?.length <= engOptionCount) {
                setShowEngDeleteIcon(false)
            }


        }
        else {

            if (checkedRegInputToggle[indexToRemove] == true)
                checkedRegInputToggle[indexToRemove] = false
            // Remove the element at the specified index
            var options_removed = regOptionValues.splice(indexToRemove, 1);

            // To remove trailing empty strings, you can use a loop or filter
            options_removed = options_removed.filter(value => value !== '');
            if (engOptionValues?.length <= regOptionCount) {
                setShowRegDeleteIcon(false)
            }

        }
    };

    const showConfirmationtoggle = (from, type, index) => {
        setConfirmationFrom(from)
        setConfirmationType(type)
        setConfirmationOptIndex(index)
        setShowConfirmationModal(!showConfirmationModal)
    };

    const clearData = () => {
        if (confirmationFrom === 'english') {
            if (confirmationType === 'textarea') {
                setEngQnTxt('')
            }
            setCheckedEngQnEditorEble(!checkedEngQnEditorEble)
        }

        if (confirmationFrom === 'regional') {
            if (confirmationType === 'textarea') {
                setRegQnTxt('')
                setRegNotesTxt('')
            }
            setCheckedRegQnEditorEble(!checkedRegQnEditorEble)
            setCheckedRegNotesEditorEble(!checkedRegNotesEditorEble)
        }

        if (confirmationFrom === 'english_options') {
            if (confirmationType === 'textarea') {
                handleEngOptionInputChange(confirmationOptIndex, '')
            }
            handleCheckboxInputValue(confirmationOptIndex)
        }
        if (confirmationFrom === 'regional_options') {
            if (confirmationType === 'textarea') {
                handleRegOptionInputChange(confirmationOptIndex, '')
            }
            handleCheckboxRegInputValue(confirmationOptIndex)
        }

        setConfirmationFrom('')
        setConfirmationType('')
        setConfirmationOptIndex('')
        setShowConfirmationModal(!showConfirmationModal)

    }
    async function handleApproveClick() {
        const user = getLoggedInUser()





        // engOptionValues = [
        //     "dsfdssd",
        //     "",
        //     "",
        //     ""
        // ]
        // checkedInputToggle = {
        //     "1": true,
        //     "2": false
        // }
        // checkedAnswerToggle = {
        //     "1": true
        // }


        var optionsArray = []
        //Frame eng language options
        if (langArr?.includes("1")) {
            for (var i = 0; i < engOptionValues?.length; i++) {
                var optionJson = {};
                if (checkedInputToggle[i] == true) {
                    optionJson['qa_contentflag'] = 1;
                    optionJson['qa_name'] = editorEditloadEngOptions[i]['qa_name'] //engOptionsRef.current[i]?.current?.contentWindow?.updatedContent



                }
                else {
                    optionJson['qa_name'] = engOptionValues[i];
                    optionJson['qa_contentflag'] = 0;
                }

                if (checkedAnswerToggle[i] == true) {
                    optionJson['qa_answerflag'] = 1;
                }
                else {
                    optionJson['qa_answerflag'] = 0;
                }
                optionJson['qa_langflag'] = 1;
                if (engQAIDArr.length > 0)
                    optionJson['qaid'] = engQAIDArr[i];
                if (optionJson['qa_name'] != "" && optionJson['qa_name'] != undefined)
                    optionsArray.push(optionJson);
            }
        }
        // Frmae regional language options
        if (langArr?.includes("2")) {
            for (var i = 0; i < regOptionValues?.length; i++) {
                var optionJson = {};
                if (checkedRegInputToggle[i] == true) {
                    optionJson['qa_contentflag'] = 1;
                    optionJson['qa_name'] =  editorEditloadRegOptions[i]['qa_name'] //regOptionsRef.current[i]?.current?.contentWindow?.updatedContent



                }
                else {
                    optionJson['qa_name'] = regOptionValues[i];
                    optionJson['qa_contentflag'] = 0;
                }

                if (checkedRegAnswerToggle[i] == true) {
                    optionJson['qa_answerflag'] = 1;
                }
                else {
                    optionJson['qa_answerflag'] = 0;
                }
                optionJson['qa_langflag'] = 2;
                if (regQAIDArr.length > 0)
                    optionJson['qaid'] = regQAIDArr[i];
                if (optionJson['qa_name'] != "" && optionJson['qa_name'] != undefined)
                    optionsArray.push(optionJson);
            }

        }



        const engQnEditorValue = editorEngQnTxt //engQnIframeRef?.current?.contentWindow?.updatedContent;
        const regQnEditorValue = editorRegQnTxt//regQnIframeRef?.current?.contentWindow?.updatedContent;
        const engNotesEditorValue =  editorEngNotesTxt//enNotesIframeRef?.current?.contentWindow?.updatedContent;
        const regNotesEditorValue = editorRegNotesTxt //regNotesIframeRef?.current?.contentWindow?.updatedContent;




        var questionArray = [];
        var engQnJson = {};
        var regQnJson = {};


        if (checkedEngQnEditorEble == true) {
            engQnJson['qt_contentflag'] = 1;
            engQnJson['qt_text'] = engQnEditorValue;
        }
        else {
            engQnJson['qt_contentflag'] = 0;
            // engQnJson['qt_notes_contentflag'] = 0;
            engQnJson['qt_text'] = engQnTxt;
            // engQnJson['qt_notes'] = notes;

        }
        engQnJson['qt_langflag'] = 1;

        if (checkedEngNotesEditorEble == true) {
            engQnJson['qt_notes_contentflag'] = 1;
            engQnJson['qt_notes'] = engNotesEditorValue;
        }
        else {
            engQnJson['qt_notes_contentflag'] = 0;
            engQnJson['qt_notes'] = notes;

        }
        // engQnJson['qt_langflag'] = 1;
        if (checkedRegQnEditorEble == true) {
            regQnJson['qt_contentflag'] = 1;
            regQnJson['qt_text'] = regQnEditorValue;
            // reqQnJson['qt_notes'] = notes;

        }
        else {
            regQnJson['qt_contentflag'] = 0;
            // regQnJson['qt_notes_contentflag'] = 0;
            regQnJson['qt_text'] = regQnTxt;
            // regQnJson['qt_notes'] = reqnotes;

        }
        regQnJson['qt_langflag'] = 2;
        if (checkedRegNotesEditorEble == true) {
            regQnJson['qt_notes_contentflag'] = 1;
            regQnJson['qt_notes'] = regNotesEditorValue;
            // reqQnJson['qt_notes'] = notes;

        }
        else {
            regQnJson['qt_notes_contentflag'] = 0;
            // regQnJson['qt_text'] = regQnTxt;
            regQnJson['qt_notes'] = regNotesTxt;

        }

        var eng_approve_status = 0
        var reg_approve_status = 0



        engQnJson['qt_approve_status'] = 0
        regQnJson['qt_approve_status'] = 0

        if(approveStatusArray.length > 0)
        {
            if(approveStatusArray.length == 1)
            {
                engQnJson['qt_approve_status'] =  approveStatusArray[0] != 0 ? approveStatusArray[0] :0
                eng_approve_status = approveStatusArray[0] != 0 ? approveStatusArray[0] :0

                regQnJson['qt_approve_status'] =  approveStatusArray[0]!= 0 ? approveStatusArray[0] :0
                reg_approve_status =approveStatusArray[0]!= 0 ? approveStatusArray[0] :0
            }

           
            if(approveStatusArray.length > 1)
            {
                engQnJson['qt_approve_status'] =  approveStatusArray[0] != 0 ? approveStatusArray[0] :0
                eng_approve_status = approveStatusArray[0] != 0 ? approveStatusArray[0] :0

                regQnJson['qt_approve_status'] =  approveStatusArray[1]!= 0 ? approveStatusArray[1] :0
                reg_approve_status =approveStatusArray[1]!= 0 ? approveStatusArray[1] :0
            }
        }

        if (engQnJson && engQnJson?.qt_text !== "" && approveLang == 1) {
           
            engQnJson['qt_approve_status'] = 4
            eng_approve_status = 4
        }
        if (regQnJson && regQnJson?.qt_text !== "" && approveLang == 2) {
       
            regQnJson['qt_approve_status'] = 4
            reg_approve_status = 4
        }


        // engQnJson['qa_approve_status'] = 1

        regQnJson['qt_langflag'] = 2;
        if (engQnJson['qt_text'] != "" && engQnJson['qt_text'] != undefined || (engQnJson['qt_notes'] != "" && engQnJson['qt_notes'] != undefined))
            questionArray.push(engQnJson);
        // if (engQnJson['qt_notes'] != "" && engQnJson['qt_notes'] != undefined)
        //     questionArray.push(engQnJson)
        if (regQnJson['qt_text'] != "" && regQnJson['qt_text'] != undefined || (regQnJson['qt_notes'] != "" && regQnJson['qt_notes'] != undefined))
            questionArray.push(regQnJson);
        // if (regQnJson['qt_notes'] != "" && regQnJson['qt_notes'] != undefined)
        //     questionArray.push(regQnJson);
        // Question validation
        var checkQn = questionArray.filter(e => e.qt_text != "")

        // if (checkQn?.length != langArr?.length) {
        //     toast.error("Please enter the question")
        //     return false
        // }


        // approve button checking commented by pradeeep

        // if (langArr) {
        //     if (langArr?.length == 2) {
        //         if (langArr?.includes("1") || langArr?.includes("2")) {
        //             var englang_option_count = optionsArray.filter(e => e.qa_langflag == 1 && e.qa_text != "")
        //             var reglang_option_count = optionsArray.filter(e => e.qa_langflag == 2 && e.qa_text != "")

        //             if (englang_option_count?.length != engOptionValues?.length && reglang_option_count?.length != regOptionValues?.length) {
        //                 toast.error("Please enter the English options")
        //                 return false
        //             }

        //         }
        //     } else {
        //         var englang_option_count = optionsArray.filter(e => e.qa_langflag == 1 && e.qa_text != "")
        //         var reglang_option_count = optionsArray.filter(e => e.qa_langflag == 2 && e.qa_text != "")
        //         if (langArr?.includes("1")) {

        //             if ((englang_option_count?.length != engOptionValues?.length)) {
        //                 toast.error("Please enter the options")
        //                 return false
        //             }

        //         } else if (langArr?.includes("2")) {
        //             if ((reglang_option_count?.length != regOptionValues?.length)) {
        //                 toast.error("Please enter the options")
        //                 return false
        //             }

        //         }
        //     }
        // }





        // if (langArr?.includes("1")) {
        //     var englang_option_count = optionsArray.filter(e => e.qa_langflag == 1 && e.qa_text != "")
        //     if (englang_option_count?.length != engOptionValues?.length) {
        //         toast.error("Please enter the  english options")
        //         return false
        //     }
        // }

        // if (langArr?.includes("2")) {

        //     var reglang_option_count = optionsArray.filter(e => e.qa_langflag == 2 && e.qa_text != "")

        //     if (reglang_option_count?.length != regOptionValues?.length) {
        //         toast.error("Please enter the options")
        //         return false
        //     }
        // }

        //Check any of the option is mark as answer 
        var checkAns = optionsArray.filter(e => e.qa_answerflag == 1)

        // if (checkAns?.length != langArr?.length) {
        //     toast.error("Please mark the answer")
        //     return false
        // }

        var isClone = localStorage.getItem('isClone');
        var qts;
        var vts;
        if (generalsetting && generalsetting.length > 0 && generalsetting[0].gs_qa_approval == 1) {

            if (qid == 0 || isClone == '1') {


                if (user.user_previleges.filter(p =>
                    Number(p.urp_mid) === constants.mainMenuList.QA && (p.urp_type === constants.menuTypes.Special)).length === 1) {
                    qts = 1
                    vts = 3
                }
                else {
                    qts = 3
                    vts = 3
                }
                // if (user.user_previleges.filter(p =>
                //     Number(p.urp_mid) === constants.mainMenuList.QA && (p.urp_type === constants.menuTypes.Create || p.urp_type === constants.menuTypes.Special)).length === 2) {


                //         qts = 1
                // }
                // else if (user.user_previleges.filter(p =>
                //     Number(p.urp_mid) === constants.mainMenuList.QA && (p.urp_type === constants.menuTypes.Create && p.urp_type !== constants.menuTypes.Special)).length === 1 && user.user_previleges.filter(p =>
                //         Number(p.urp_mid) === constants.mainMenuList.QA && (p.urp_type === constants.menuTypes.Create || p.urp_type !== constants.menuTypes.Special)).length === 2) {
                //             qts = 3
                // }
                // else if (user.user_previleges.filter(p =>
                //     Number(p.urp_mid) === constants.mainMenuList.QA && (p.urp_type === constants.menuTypes.Create || p.urp_type !== constants.menuTypes.Special)).length === 4 && user.user_previleges.filter(p =>
                //         Number(p.urp_mid) === constants.mainMenuList.QA && (p.urp_type === constants.menuTypes.Create && p.urp_type !== constants.menuTypes.Special)).length === 1) {
                //             qts = 1
                // }
                // else if(user.user_previleges.filter(p =>  Number(p.urp_mid) === constants.mainMenuList.QA && (Number(p.urp_type) === constants.menuTypes.Create ||  Number(p.urp_type) === constants.menuTypes.Modify)).length == 2){
                //     qts = 3
                // }
                // else if(user.user_previleges.filter(p => Number()))
                // else if(user.user_previleges.filter(p => Number(p.urp_mid) === constants.mainMenuList.QA))
            } else {
                if (approveQues == 3) {
                    qts = 3
                    vts = 3
                }
                else {
                    if (statusQues === true) {
                        qts = 1
                        vts = 3
                    } else {
                        qts = 2
                        vts = 3
                    }
                }
            }

        } else {
            if (qid == 0 || isClone == '1') {
                qts = 1;
                vts = 4
            } else {
                if (statusQues === true) {
                    qts = 1
                    vts = 4
                } else {
                    qts = 2
                    vts = 4
                }
            }
        }

        var data;
        var q_approval_status = ""
        if(langArr?.includes("1"))
        {
            q_approval_status = eng_approve_status.toString()
        }
        if(langArr?.includes("2"))
        {
            q_approval_status = q_approval_status+reg_approve_status.toString()
        }
            
        if (qid !== 0) {
            const q_sid = selectedSubjectName != null ? selectedSubjectName.value : 0;
            const q_sscid = selectedSubjectSubcatName != null ? selectedSubjectSubcatName.value : 0;
            data = {
                "user_id": Number(user.uid),
                "process_type": qid == 0 || isClone == '1' ? 'save' : 'update',
                "qid": isClone == '1' ? 0 : Number(qid),

                "q_exctid": Number(selectedBoardName?.value) || 0,
                "q_sid": Number(q_sid),
                "q_sscid": Number(q_sscid) || 0,
                "q_tid": Number(selectedTopicName?.value) || 0,
                "q_exlid": Number(selectedExamLevel?.value) || 0,
                "q_extid": Number(selectedExamType?.value),
                "q_ex_paper_id": Number(selectedExamPaper?.value),
                "q_shuffle": Number(shuffle),
                "q_stsid": qts,
                "q_options": optionsArray,
                "q_questions": questionArray,
                "q_vstatus": 3,
                "q_reason": '',
                "q_approval_flag": 0,
                "qa_approve_status": 0,
                "q_approval_status": q_approval_status
            }
        }



        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
        const response1 = await axios.post(url.BASE_URL + 'qa/saveQA', data, config)
        if (response1 && response1.statusFlag === 3) { // user validation
            toast.error(response1.message, { autoClose: 2000 });
            localStorage.removeItem("authUser");
            sessionStorage.removeItem("SESSION_KEY")
            window.location.reload()
            return
        }
        if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
            const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
            if (refresh) {
                if (Number(refresh) === constants.invalidRefreshToken) {
                    logoutUser()
                } else {
                    handleApproveClick() // calling same function again getting refresh token
                }
            }
        }


        if (response1 && response1.data && response1.data.body) {

            toast.success(response1.data.body.message, { autoClose: 2000 });
            if (saveClone == 1) {
                setNotes("")
                setEngQnTxt("")

                setRegNotesTxt("")
                setRegQnTxt("")


                setEngOptionValues([])
                setEngQAIDArr([])
                setCheckedInputToggle("")
                setCheckedAnswerToggle("")

                setRegOptionValues([])
                setRegQAIDArr([])
                setCheckedRegInputToggle("")
                setCheckedRegAnswerToggle("")
                setCheckedEngQnEditorEble(false)
                setCheckedRegQnEditorEble(false)
                setCheckedEngNotesEditorEble(false)
                setCheckedRegNotesEditorEble(false)



                editLoadQA(response1.data.body.updated_qid)
                localStorage.setItem('isClone', '1')
                // qid = 0;
            }
            else {
                // setTimeout(() => {
                //     if (Number(localStorage.getItem('isClone')) === 3) {
                //         navigate(
                //             `/list-question-approval`
                //         )
                //     } else {
                //         navigate(
                //             `/questions`
                //         )
                //     }
                // }, 1000);
                setApproveModal(false)
                var next = Next();
                if (next === null) {
                    setQaListStatus(false)
                    if (qaList[0]?.qid && qaList.length > 1) {
                        navigate(`/add-questions/${qaList[0]?.qid}`);
                        editLoadQA(qaList[0]?.qid)
                    } else {
                        navigate(
                            `/questions`
                        )
                    }
                } else {
                    setQaListStatus(false)
                }
            }
        } else {
            toast.error(response1.data.message, { autoClose: 2000 });

        }
        engOptionsRef.current.map(
            ref => {
                console.log(ref?.current?.contentWindow?.updatedContent, 'ref')
            }
        );
        regOptionsRef.current.map(
            ref => {
                console.log(ref?.current?.contentWindow?.updatedContent, 'ref')
            }
        );
    }

    async function handleRejectClick() {
        const user = getLoggedInUser()





        // engOptionValues = [
        //     "dsfdssd",
        //     "",
        //     "",
        //     ""
        // ]
        // checkedInputToggle = {
        //     "1": true,
        //     "2": false
        // }
        // checkedAnswerToggle = {
        //     "1": true
        // }


        var optionsArray = []
        //Frame eng language options
        if (langArr?.includes("1")) {
            for (var i = 0; i < engOptionValues?.length; i++) {
                var optionJson = {};
                if (checkedInputToggle[i] == true) {
                    optionJson['qa_contentflag'] = 1;
                    optionJson['qa_name'] = editorEditloadEngOptions[i]['qa_name'] //engOptionsRef.current[i]?.current?.contentWindow?.updatedContent



                }
                else {
                    optionJson['qa_name'] = engOptionValues[i];
                    optionJson['qa_contentflag'] = 0;
                }

                if (checkedAnswerToggle[i] == true) {
                    optionJson['qa_answerflag'] = 1;
                }
                else {
                    optionJson['qa_answerflag'] = 0;
                }
                optionJson['qa_langflag'] = 1;
                if (engQAIDArr.length > 0) {
                    optionJson['qaid'] = engQAIDArr[i];
                } else {
                    optionJson['qaid'] = '';
                }
                if (optionJson['qa_name'] != "" && optionJson['qa_name'] != undefined)
                    optionsArray.push(optionJson);
            }
        }
        // Frmae regional language options
        if (langArr?.includes("2")) {
            for (var i = 0; i < regOptionValues?.length; i++) {
                var optionJson = {};
                if (checkedRegInputToggle[i] == true) {
                    optionJson['qa_contentflag'] = 1;
                    optionJson['qa_name'] =  editorEditloadRegOptions[i]['qa_name'] //regOptionsRef.current[i]?.current?.contentWindow?.updatedContent


                }
                else {
                    optionJson['qa_name'] = regOptionValues[i];
                    optionJson['qa_contentflag'] = 0;
                }

                if (checkedRegAnswerToggle[i] == true) {
                    optionJson['qa_answerflag'] = 1;
                }
                else {
                    optionJson['qa_answerflag'] = 0;
                }
                optionJson['qa_langflag'] = 2;
                if (regQAIDArr.length > 0) {
                    optionJson['qaid'] = regQAIDArr[i];
                } else {
                    optionJson['qaid'] = '';
                }
                if (optionJson['qa_name'] != "" && optionJson['qa_name'] != undefined)
                    optionsArray.push(optionJson);
            }

        }


        const engQnEditorValue = editorEngQnTxt //engQnIframeRef?.current?.contentWindow?.updatedContent;
        const regQnEditorValue = editorRegQnTxt//regQnIframeRef?.current?.contentWindow?.updatedContent;
        const engNotesEditorValue =  editorEngNotesTxt//enNotesIframeRef?.current?.contentWindow?.updatedContent;
        const regNotesEditorValue = editorRegNotesTxt //regNotesIframeRef?.current?.contentWindow?.updatedContent;




        var questionArray = [];
        var engQnJson = {};
        var regQnJson = {};


        if (checkedEngQnEditorEble == true) {
            engQnJson['qt_contentflag'] = 1;
            engQnJson['qt_text'] = engQnEditorValue;
        }
        else {
            engQnJson['qt_contentflag'] = 0;
            // engQnJson['qt_notes_contentflag'] = 0;
            engQnJson['qt_text'] = engQnTxt;
            // engQnJson['qt_notes'] = notes;

        }
        engQnJson['qt_langflag'] = 1;

        if (checkedEngNotesEditorEble == true) {
            engQnJson['qt_notes_contentflag'] = 1;
            engQnJson['qt_notes'] = engNotesEditorValue;
        }
        else {
            engQnJson['qt_notes_contentflag'] = 0;
            engQnJson['qt_notes'] = notes;

        }
        // if (Object.keys(engQnJson).length != 0 || engQnJson?.qt_text !== "") {
        //     engQnJson['qt_approve_status'] = 0
        //     engQnJson['qt_reason'] = rejectValidation.values.reason

        // }

        // if (Object.keys(regQnJson).length !== 0 || regQnJson.qt_text !== "") {
        //     regQnJson['qt_approve_status'] = 0;
        //     regQnJson['qt_reason'] = rejectValidation.values.reason;
        // } else {
        //     console.log("Condition not met: Either the object is empty or qt_text is empty.");
        // }


            
        var eng_approve_status = 0
        var reg_approve_status = 0



        engQnJson['qt_approve_status'] = 0
        regQnJson['qt_approve_status'] = 0

        if(approveStatusArray.length > 0)
        {
            if(approveStatusArray.length == 1)
            {
                engQnJson['qt_approve_status'] =  approveStatusArray[0] != 0 ? approveStatusArray[0] :0
                eng_approve_status = approveStatusArray[0] != 0 ? approveStatusArray[0] :0

                regQnJson['qt_approve_status'] =  approveStatusArray[0]!= 0 ? approveStatusArray[0] :0
                reg_approve_status =approveStatusArray[0]!= 0 ? approveStatusArray[0] :0
            }

           
            if(approveStatusArray.length > 1)
            {
                engQnJson['qt_approve_status'] =  approveStatusArray[0] != 0 ? approveStatusArray[0] :0
                eng_approve_status = approveStatusArray[0] != 0 ? approveStatusArray[0] :0

                regQnJson['qt_approve_status'] =  approveStatusArray[1]!= 0 ? approveStatusArray[1] :0
                reg_approve_status =approveStatusArray[1]!= 0 ? approveStatusArray[1] :0
            }
        }

        if (engQnJson && engQnJson?.qt_text !== "" && rejectLang == 1) {
            engQnJson['qt_reason'] = rejectValidation.values.reason
            eng_approve_status = 5
            engQnJson['qt_approve_status'] = 5
        }
        if (regQnJson && regQnJson?.qt_text !== "" && rejectLang == 2) {
            regQnJson['qt_reason'] = rejectValidation.values.reason;
            regQnJson['qt_approve_status'] = 5
            reg_approve_status = 5
        }




        if (checkedRegQnEditorEble == true) {
            regQnJson['qt_contentflag'] = 1;
            regQnJson['qt_text'] = regQnEditorValue;
        }
        else {
            regQnJson['qt_contentflag'] = 0;
            regQnJson['qt_text'] = regQnTxt;
            // regQnJson['qt_notes'] = reqnotes;

        }
        regQnJson['qt_langflag'] = 2;
        if (checkedRegNotesEditorEble == true) {
            regQnJson['qt_notes_contentflag'] = 1;
            regQnJson['qt_notes'] = regNotesEditorValue;
            // reqQnJson['qt_notes'] = notes;

        }
        else {
            regQnJson['qt_notes_contentflag'] = 0;
            // regQnJson['qt_text'] = regQnTxt;
            regQnJson['qt_notes'] = regNotesTxt;

        }
        regQnJson['qt_langflag'] = 2;
        if (engQnJson['qt_text'] != "" && engQnJson['qt_text'] != undefined || (engQnJson['qt_notes'] != "" && engQnJson['qt_notes'] != undefined))
            questionArray.push(engQnJson);
        // if (engQnJson['qt_notes'] != "" && engQnJson['qt_notes'] != undefined)
        //     questionArray.push(engQnJson)
        if (regQnJson['qt_text'] != "" && regQnJson['qt_text'] != undefined || (regQnJson['qt_notes'] != "" && regQnJson['qt_notes'] != undefined))
            questionArray.push(regQnJson);
        // if (regQnJson['qt_notes'] != "" && regQnJson['qt_notes'] != undefined)
        //     questionArray.push(regQnJson);


        // Question validation
        var checkQn = questionArray.filter(e => e.qt_text != "")

        // if (checkQn?.length != langArr?.length) {
        //     toast.error("Please enter the question")
        //     return false
        // }

        // if (langArr) {
        //     if (langArr?.length == 2) {
        //         if (langArr?.includes("1") || langArr?.includes("2")) {
        //             var englang_option_count = optionsArray.filter(e => e.qa_langflag == 1 && e.qa_text != "")
        //             var reglang_option_count = optionsArray.filter(e => e.qa_langflag == 2 && e.qa_text != "")

        //             if (englang_option_count?.length != engOptionValues?.length && reglang_option_count?.length != regOptionValues?.length) {
        //                 toast.error("Please enter the English options1")
        //                 return false
        //             }

        //         }
        //     } else {
        //         var englang_option_count = optionsArray.filter(e => e.qa_langflag == 1 && e.qa_text != "")
        //         var reglang_option_count = optionsArray.filter(e => e.qa_langflag == 2 && e.qa_text != "")
        //         if (langArr?.includes("1")) {

        //             // if ((englang_option_count?.length != engOptionValues?.length)) {
        //             //     toast.error("Please enter the options")
        //             //     return false
        //             // }

        //         }
        //     }
        // }

        var checkEngQn  = questionArray.filter(e=>e.qt_langflag ==  1)
        var checkRegQn  = questionArray.filter(e=>e.qt_langflag ==  2)

        // var checkEngOptions  = questionArray.find(e=>e.qt_langflag ==  1)
        // var checkRegOptions  = questionArray.find(e=>e.qt_langflag ==  2)
        var englang_option_count = optionsArray.filter(e => e.qa_langflag == 1 && e.qa_text != "")
        var reglang_option_count = optionsArray.filter(e => e.qa_langflag == 2 && e.qa_text != "")
       

        if (checkEngQn.length == 1) {
          
            if (englang_option_count?.length < 4) {
                toast.error("Please enter the english options")
                return false
            }
        }

        if (checkRegQn.length == 1) {        

            if (reglang_option_count?.length < 4) {
                toast.error("Please enter the regional options")
                return false
            }
        }

        if (englang_option_count.length >= 1) {
          
            if (checkEngQn.length == 0) {
                toast.error("Please enter the english question")
                return false
            }
        }

        if (reglang_option_count.length >= 1) {        
          
            if (checkRegQn.length == 0) {        
                toast.error("Please enter the regional question")
                return false
            }
        }




        // if (langArr?.includes("1")) {
        //     var englang_option_count = optionsArray.filter(e => e.qa_langflag == 1 && e.qa_text != "")
        //     if (englang_option_count?.length != engOptionValues?.length) {
        //         toast.error("Please enter the  english options")
        //         return false
        //     }
        // }

        // if (langArr?.includes("2")) {

        //     var reglang_option_count = optionsArray.filter(e => e.qa_langflag == 2 && e.qa_text != "")

        //     if (reglang_option_count?.length != regOptionValues?.length) {
        //         toast.error("Please enter the options")
        //         return false
        //     }
        // }

        //Check any of the option is mark as answer 
        var checkAns = optionsArray.filter(e => e.qa_answerflag == 1)

        // if (checkAns?.length != langArr?.length) {
        //     toast.error("Please mark the answer")
        //     return false
        // }

        var isClone = localStorage.getItem('isClone');
        var qts;
        if (generalsetting && generalsetting.length > 0 && generalsetting[0].gs_qa_approval == 1) {

            if (qid == 0 || isClone == '1') {


                if (user.user_previleges.filter(p =>
                    Number(p.urp_mid) === constants.mainMenuList.QA && (p.urp_type === constants.menuTypes.Special)).length === 1) {
                    qts = 1
                }
                else {
                    qts = 3
                }
                // if (user.user_previleges.filter(p =>
                //     Number(p.urp_mid) === constants.mainMenuList.QA && (p.urp_type === constants.menuTypes.Create || p.urp_type === constants.menuTypes.Special)).length === 2) {


                //         qts = 1
                // }
                // else if (user.user_previleges.filter(p =>
                //     Number(p.urp_mid) === constants.mainMenuList.QA && (p.urp_type === constants.menuTypes.Create && p.urp_type !== constants.menuTypes.Special)).length === 1 && user.user_previleges.filter(p =>
                //         Number(p.urp_mid) === constants.mainMenuList.QA && (p.urp_type === constants.menuTypes.Create || p.urp_type !== constants.menuTypes.Special)).length === 2) {
                //             qts = 3
                // }
                // else if (user.user_previleges.filter(p =>
                //     Number(p.urp_mid) === constants.mainMenuList.QA && (p.urp_type === constants.menuTypes.Create || p.urp_type !== constants.menuTypes.Special)).length === 4 && user.user_previleges.filter(p =>
                //         Number(p.urp_mid) === constants.mainMenuList.QA && (p.urp_type === constants.menuTypes.Create && p.urp_type !== constants.menuTypes.Special)).length === 1) {
                //             qts = 1
                // }
                // else if(user.user_previleges.filter(p =>  Number(p.urp_mid) === constants.mainMenuList.QA && (Number(p.urp_type) === constants.menuTypes.Create ||  Number(p.urp_type) === constants.menuTypes.Modify)).length == 2){
                //     qts = 3
                // }
                // else if(user.user_previleges.filter(p => Number()))
                // else if(user.user_previleges.filter(p => Number(p.urp_mid) === constants.mainMenuList.QA))
            } else {
                if (approveQues == 3) {
                    qts = 3
                }
                else {
                    if (statusQues === true) {
                        qts = 1
                    } else {
                        qts = 2
                    }
                }
            }

        } else {
            if (qid == 0 || isClone == '1') {
                qts = 1;
            } else {
                if (statusQues === true) {
                    qts = 1
                } else {
                    qts = 2
                }
            }
        }
        var q_approval_status = ""
        if(langArr?.includes("1"))
        {
            q_approval_status = eng_approve_status.toString()
        }
        if(langArr?.includes("2"))
        {
            q_approval_status = q_approval_status+reg_approve_status.toString()
        }
            
        var data;
        if (qid !== 0) {
            const q_sid = selectedSubjectName != null ? selectedSubjectName.value : 0;
            const q_sscid = selectedSubjectSubcatName != null ? selectedSubjectSubcatName.value : 0;
            data = {
                "user_id": Number(user.uid),
                "process_type": qid == 0 || isClone == '1' ? 'save' : 'update',
                "qid": isClone == '1' ? 0 : Number(qid),

                "q_exctid": Number(selectedBoardName?.value) || 0,
                "q_sid": Number(q_sid),
                "q_sscid": Number(q_sscid) || 0,
                "q_tid": Number(selectedTopicName?.value) || 0,
                "q_exlid": Number(selectedExamLevel?.value) || 0,
                "q_extid": Number(selectedExamType?.value),
                "q_ex_paper_id": Number(selectedExamPaper?.value),
                "q_shuffle": Number(shuffle),
                "q_approval_flag": 0,
                "q_approval_status": q_approval_status,
                "q_stsid": qts,
                "q_options": optionsArray,
                "q_questions": questionArray,
                "q_vstatus": 5,
                "q_reason": rejectValidation.values.reason !== '' && rejectValidation.values.reason !== undefined && rejectValidation.values.reason !== null ? rejectValidation.values.reason : ''
            }
        }


        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
        const response1 = await axios.post(url.BASE_URL + 'qa/saveQA', data, config)
        if (response1 && response1.statusFlag === 3) { // user validation
            toast.error(response1.message, { autoClose: 2000 });
            localStorage.removeItem("authUser");
            sessionStorage.removeItem("SESSION_KEY")
            window.location.reload()
            return
        }
        if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
            const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
            if (refresh) {
                if (Number(refresh) === constants.invalidRefreshToken) {
                    logoutUser()
                } else {
                    handleRejectClick() // calling same function again getting refresh token
                }
            }
        }


        if (response1 && response1.data && response1.data.body) {

            toast.success(response1.data.body.message, { autoClose: 2000 });
            if (saveClone == 1) {
                setNotes("")
                setEngQnTxt("")

                setRegNotesTxt("")
                setRegQnTxt("")


                setEngOptionValues(["","","","",""])
                setEngQAIDArr([])
                setCheckedInputToggle("")
                setCheckedAnswerToggle("")

                setRegOptionValues(["","","","",""])
                setRegQAIDArr([])
                setCheckedRegInputToggle("")
                setCheckedRegAnswerToggle("")
                setCheckedEngQnEditorEble(false)
                setCheckedRegQnEditorEble(false)
                setCheckedEngNotesEditorEble(false)
                setCheckedRegNotesEditorEble(false)



                editLoadQA(response1.data.body.updated_qid)
                localStorage.setItem('isClone', '1')
                // qid = 0;
            }
            else {
                // setTimeout(() => {
                //     if (Number(localStorage.getItem('isClone')) === 3) {
                //         navigate(
                //             `/list-question-approval`
                //         )
                //     } else {
                //         navigate(
                //             `/questions`
                //         )
                //     }
                // }, 1000);
                setRejectModal(false);
                var next = Next();
                if (next === null) {
                    setQaListStatus(false)
                    if (qaList[0]?.qid && qaList.length > 1) {
                        navigate(`/add-questions/${qaList[0]?.qid}`);
                        editLoadQA(qaList[0]?.qid)
                    } else {
                        navigate(
                            `/questions`
                        )
                    }
                } else {
                    setQaListStatus(false)
                }

            }
        } else {
            toast.error(response1.data.message, { autoClose: 2000 });

        }
        engOptionsRef.current.map(
            ref => {
                console.log(ref?.current?.contentWindow?.updatedContent, 'ref')
            }
        );
        regOptionsRef.current.map(
            ref => {
                console.log(ref?.current?.contentWindow?.updatedContent, 'ref')
            }
        );
    }

    const initializeEditor = (varref, vartxt, varind) => {
        window.CKEDITOR.ClassicEditor.create(varref.current, {
            editor: {
                config: {
                    contents: '<p>Your default content here</p>',
                }
            },
            toolbar: {
                items: [
                    'exportPDF', 'exportWord', '|',
                    'findAndReplace', 'selectAll', '|',
                    'heading', '|',
                    'bold', 'italic', 'strikethrough', 'underline', 'code', 'subscript', 'superscript', 'removeFormat', '|',
                    'bulletedList', 'numberedList', 'todoList', '|',
                    'outdent', 'indent', '|',
                    'undo', 'redo', '-',
                    'fontSize', 'fontColor', 'fontBackgroundColor', 'highlight', '|',
                    'alignment', '|',
                    'link', 'uploadImage', 'blockQuote', 'insertTable', 'mediaEmbed', 'codeBlock', 'htmlEmbed', '|',
                    'specialCharacters', 'horizontalLine', 'pageBreak', '|',
                ],
                shouldNotGroupWhenFull: true
            },
            initialData: vartxt, // Pass the last known content to the editor
            list: {
                properties: {
                    styles: true,
                    startIndex: true,
                    reversed: true
                }
            },
            heading: {
                options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                    { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
                    { model: 'heading5', view: 'h5', title: 'Heading 5', class: 'ck-heading_heading5' },
                    { model: 'heading6', view: 'h6', title: 'Heading 6', class: 'ck-heading_heading6' }
                ]
            },
            placeholder: 'Type here...',
            fontFamily: {
                options: ['Times New Roman, Times, serif'],
                supportAllValues: true
            },
            fontSize: {
                options: [10, 12, 14, 'default', 18, 20, 22],
                supportAllValues: true
            },
            htmlSupport: {
                allow: [
                    {
                        name: /.*/,
                        attributes: true,
                        classes: true,
                        styles: true
                    }
                ]
            },
            htmlEmbed: {
                showPreviews: true
            },
            link: {
                decorators: {
                    addTargetToExternalLinks: true,
                    defaultProtocol: 'https://',
                    toggleDownloadable: {
                        mode: 'manual',
                        label: 'Downloadable',
                        attributes: { download: 'file' }
                    }
                }
            },
            mention: {
                feeds: [
                    {
                        marker: '@',
                        feed: ['@apple', '@bears', '@brownie', '@cake', '@candy', '@chocolate', '@cookie', '@cotton', '@cream'],
                        minimumCharacters: 1
                    }
                ]
            },
            removePlugins: [
                'AIAssistant', 'CKBox', 'CKFinder', 'EasyImage', 'RealTimeCollaborativeComments', 'RealTimeCollaborativeTrackChanges',
                'RealTimeCollaborativeRevisionHistory', 'PresenceList', 'Comments', 'TrackChanges', 'TrackChangesData', 'RevisionHistory',
                'Pagination', 'WProofreader', 'MathType', 'SlashCommand', 'Template', 'DocumentOutline', 'FormatPainter', 'TableOfContents',
                'PasteFromOfficeEnhanced'
            ]
        }).then(editor => {
            if(varind == "engqn")
                editorEngQnInstance.current = editor;
            if(varind == "regqn")
                editorRegQnInstance.current = editor;
            if(varind == "engnotes")
            {
                
                editorEngNotesInstance.current = editor;
            }
             
            if(varind == "regnotes")
                editorRegNotesInstance.current = editor;

            if(varind == "engoption0")
                editorengOptionsInstance.current[0].current = editor;
            if(varind == "engoption1")
                editorengOptionsInstance.current[1].current = editor;                
            if(varind == "engoption2")
                editorengOptionsInstance.current[2].current = editor;
            if(varind == "engoption3")
                editorengOptionsInstance.current[3].current = editor;
            if(varind == "engoption4")
                editorengOptionsInstance.current[4].current = editor;

            
            if(varind == "regoption0")
                editorregOptionsInstance.current[0].current = editor;
            if(varind == "regoption1")
                editorregOptionsInstance.current[1].current = editor;                
            if(varind == "regoption2")
                editorregOptionsInstance.current[2].current = editor;
            if(varind == "regoption3")
                editorregOptionsInstance.current[3].current = editor;
            if(varind == "regoption4")
                editorregOptionsInstance.current[4].current = editor;
                
            editor.plugins.get('Clipboard').on('inputTransformation', (evt, data) => {
                if (data.dataType === 'fragment') {
                    data.data.data.forEach(entry => {
                        if (entry.name === 'p') {
                            entry.attributes.style = 'font-family: "Times New Roman", Times, serif;';
                        }
                    });
                }
            });

            editor.model.document.on('change:data', () => {
            
                if(varind == "engqn")
                    setEditorEngQnTxt(editor.getData());
                if(varind == "regqn")
                    setEditorRegQnTxt(editor.getData());
                if(varind == "engnotes")
                {                    
                    setEditorEngNotesTxt(editor.getData());
                }                    
                if(varind == "regnotes")
                    setEditorRegNotesTxt(editor.getData());

                
                if(varind == "engoption0")
                {   editorEditloadEngOptions[0]['qa_name'] = editor.getData();

                }
                
                if(varind == "engoption1")
                    editorEditloadEngOptions[1]['qa_name']  = editor.getData();                
                if(varind == "engoption2")
                    editorEditloadEngOptions[2]['qa_name']  = editor.getData();
                if(varind == "engoption3")
                    editorEditloadEngOptions[3]['qa_name']  = editor.getData();
                if(varind == "engoption4")
                    editorEditloadEngOptions[4]['qa_name']  = editor.getData();
                setEditorEditloadEngOptions(editorEditloadEngOptions)
                setCheckedInputToggle(checkedInputToggle)

                if(varind == "regoption0")
                    editorEditloadRegOptions[0]['qa_name'] = editor.getData();
                  if(varind == "regoption1")
                    editorEditloadRegOptions[1]['qa_name']  = editor.getData();                
                  if(varind == "regoption2")
                    editorEditloadRegOptions[2]['qa_name']  = editor.getData();
                  if(varind == "regoption3")
                    editorEditloadRegOptions[3]['qa_name']  = editor.getData();
                  if(varind == "regoption4")
                    editorEditloadRegOptions[4]['qa_name']  = editor.getData();
                 
               
            });
        }).catch(error => {
            console.error('Error initializing CKEditor:', error);
        });
    };
    
    useEffect(() => {
   
        if (checkedEngQnEditorEble && window.CKEDITOR && engQnIframeRef.current) {
          
            if (editorEngQnInstance.current) {
                // Destroy the previously created editor before initializing a new one
                editorEngQnInstance.current.destroy().then(() => {
                    editorEngQnInstance.current = null; // Reset the editor instance reference
                    initializeEditor(engQnIframeRef,editorEngQnTxt,'engqn'); // Initialize the editor again after destruction
                });
            } else {
                // If no editor instance exists, directly initialize it
                initializeEditor(engQnIframeRef,editorEngQnTxt,'engqn'); 
            }
        }
        else{
          
            if (editorEngQnInstance.current) {
                  
                // Destroy the previously created editor before initializing a new one
                editorEngQnInstance.current.destroy().then(() => {
                    editorEngQnInstance.current = null; // Reset the editor instance reference
                 
                });
            }  
        }
       
        // Function to initialize CKEditor
   
    }, [checkedEngQnEditorEble,initialeditorEngQnTxt]);


    


    useEffect(() => {
   
        if (checkedRegQnEditorEble && window.CKEDITOR && regQnIframeRef.current) {
          
            if (editorRegQnInstance.current) {
                // Destroy the previously created editor before initializing a new one
                editorRegQnInstance.current.destroy().then(() => {
                    editorRegQnInstance.current = null; // Reset the editor instance reference
                    initializeEditor(regQnIframeRef,editorRegQnTxt,'regqn'); // Initialize the editor again after destruction
                });
            } else {
                // If no editor instance exists, directly initialize it
                initializeEditor(regQnIframeRef,editorRegQnTxt,'regqn'); 
            }
        }
        else{
          
            if (editorRegQnInstance.current) {
                  
                // Destroy the previously created editor before initializing a new one
                editorRegQnInstance.current.destroy().then(() => {
                    editorRegQnInstance.current = null; // Reset the editor instance reference
                 
                });
            }  
        }
       
        // Function to initialize CKEditor
   
    }, [checkedRegQnEditorEble,initialeditorRegQnTxt]);


    useEffect(() => {
     
  
        if (checkedEngNotesEditorEble && window.CKEDITOR && enNotesIframeRef.current) {
          
            if (editorEngNotesInstance.current) {
                // Destroy the previously created editor before initializing a new one
                editorEngNotesInstance.current.destroy().then(() => {
                    editorEngNotesInstance.current = null; // Reset the editor instance reference
                    initializeEditor(enNotesIframeRef,editorEngNotesTxt,'engnotes'); // Initialize the editor again after destruction
                });
            } else {
                // If no editor instance exists, directly initialize it
                initializeEditor(enNotesIframeRef,editorEngNotesTxt,'engnotes'); 
            }
        }
        else{
          
            if (editorEngNotesInstance.current) {
                  
                // Destroy the previously created editor before initializing a new one
                editorEngNotesInstance.current.destroy().then(() => {
                    editorEngNotesInstance.current = null; // Reset the editor instance reference
                 
                });
            }  
        }
       
        // Function to initialize CKEditor
   
    }, [checkedEngNotesEditorEble,initialeditorEngNotesTxt]);


    useEffect(() => {
   
        if (checkedRegNotesEditorEble && window.CKEDITOR && regNotesIframeRef.current) {
          
            if (editorRegNotesInstance.current) {
                // Destroy the previously created editor before initializing a new one
                editorRegNotesInstance.current.destroy().then(() => {
                    editorRegNotesInstance.current = null; // Reset the editor instance reference
                    initializeEditor(regNotesIframeRef,editorRegNotesTxt,'regnotes'); // Initialize the editor again after destruction
                });
            } else {
                // If no editor instance exists, directly initialize it
                initializeEditor(regNotesIframeRef,editorRegNotesTxt,'regnotes'); 
            }
        }
        else{
          
            if (editorRegNotesInstance.current) {
                  
                // Destroy the previously created editor before initializing a new one
                editorRegNotesInstance.current.destroy().then(() => {
                    editorRegNotesInstance.current = null; // Reset the editor instance reference
                 
                });
            }  
        }
       
        // Function to initialize CKEditor
   
    }, [checkedRegNotesEditorEble,initialeditorRegNotesTxt]);



    useEffect(() => {
        const destroyAndReinitializeEditors = async () => {
            if (window.CKEDITOR && editorengOptionsInstance?.current) {
                // Ensure the first editor is destroyed before proceeding
  
                // Loop through all other editors
                for (let i in checkedInputToggle) {
                    if (
                        checkedInputToggle[i] &&
                        window.CKEDITOR &&
                        engOptionsRef.current[i]?.current
                    ) {
                        if (editorengOptionsInstance.current[i]?.current) {
                            // Destroy the previously created editor before initializing a new one
                            await editorengOptionsInstance.current[i].current.destroy();
                            editorengOptionsInstance.current[i].current = null; // Reset the editor instance reference
                            initializeEditor(
                                engOptionsRef.current[i],
                                editorEditloadEngOptions[i]['qa_name'],
                                'engoption' + i
                            );
                        } else {
                            // If no editor instance exists, directly initialize it
                            initializeEditor(
                                engOptionsRef.current[i],
                                editorEditloadEngOptions[i]['qa_name'],
                                'engoption' + i
                            );
                        }
                    } else {
                        if (editorengOptionsInstance.current[i]?.current) {
                            // Destroy the previously created editor if toggle is off
                            await editorengOptionsInstance.current[i].current.destroy();
                            editorengOptionsInstance.current[i].current = null; // Reset the editor instance reference
                        }
                    }
                }
            }
        };
    
        destroyAndReinitializeEditors();
    }, [checkedInputToggle, editorEditloadEngOptions]); 



    useEffect(() => {
        const destroyAndReinitializeEditorsReg= async () => {
            if (window.CKEDITOR && editorregOptionsInstance?.current) {
                // Ensure the first editor is destroyed before proceeding
  
                // Loop through all other editors
                for (let i in checkedRegInputToggle) {
                    if (
                        checkedRegInputToggle[i] &&
                        window.CKEDITOR &&
                        regOptionsRef.current[i]?.current
                    ) {
                        if (editorregOptionsInstance.current[i]?.current) {
                            // Destroy the previously created editor before initializing a new one
                            await editorregOptionsInstance.current[i].current.destroy();
                            editorregOptionsInstance.current[i].current = null; // Reset the editor instance reference
                            initializeEditor(
                                regOptionsRef.current[i],
                                editorEditloadRegOptions[i]['qa_name'],
                                'regoption' + i
                            );
                        } else {
                            // If no editor instance exists, directly initialize it
                            initializeEditor(
                                regOptionsRef.current[i],
                                editorEditloadRegOptions[i]['qa_name'],
                                'regoption' + i
                            );
                        }
                    } else {
                        if (editorregOptionsInstance.current[i]?.current) {
                            // Destroy the previously created editor if toggle is off
                            await editorregOptionsInstance.current[i].current.destroy();
                            editorregOptionsInstance.current[i].current = null; // Reset the editor instance reference
                        }
                    }
                }
            }
        };
    
        destroyAndReinitializeEditorsReg();
    }, [checkedRegInputToggle, editorEditloadRegOptions]); 





    
    async function editLoadQA(qid) {

        setLoading(true);
        const user = getLoggedInUser()

        const filter_user_id = user?.user_previleges.find(f =>
            Number(f.urp_mid) === constants.mainMenuList.QA && f.urp_type === constants.menuTypes.Permission1);


        var data = {
            user_id: Number(user.uid),
            qid: Number(qid)
        }


        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
        const response1 = await axios.post(url.BASE_URL + 'qa/editLoadQA', data, config)
        // console.log/

        if (response1 && response1.statusFlag === 3) { // user validation
            toast.error(response1.message, { autoClose: 2000 });
            localStorage.removeItem("authUser");
            sessionStorage.removeItem("SESSION_KEY")
            window.location.reload()
            return
        }
        if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
            const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
            if (refresh) {
                if (Number(refresh) === constants.invalidRefreshToken) {
                    logoutUser()
                } else {
                    await editLoadQA(qid) // calling same function again getting refresh token
                }
            }
        }
        if (response1 && response1.data && response1.data.body) {
         
            // if (!filter_user_id?.uid && response1?.data?.body?.QAData[0]?.access_user_id === user?.uid || filter_user_id?.uid) {
            setapproveQues(response1?.data?.body?.QAData[0]?.q_stsid)
            setQnObj(response1?.data?.body?.QAData[0])
           // const approve_status_array = response1.data.body.Questions.map(item => item.qt_approve_status);
            const approve_status_array = response1?.data?.body?.QAData[0]?.q_approval_status?.split('');
            const user_lang_array = response1?.data?.body?.userlang_response?.split(',')
                 setUserLangArray(user_lang_array)
                setApproveStatusArray(approve_status_array)
                
             if (response1?.data?.body?.Questions.length > 1) {
              
                setEditLoadQAData(response1?.data?.body?.Questions)


                // setQaApprovalButton
            } else if (response1?.data?.body?.Questions.length == 1) {
              
                setQaApprovalsubmitButton(Number(response1?.data?.body?.QAData[0]?.q_approval_flag) === 0 ? 1 : 0)



                // if (response1?.data?.body?.Questions.some(q))


                if (response1?.data?.body?.Questions.some(question => question.qt_langflag === 2)) {
                    setSubmitForApprovalHandling(2)
                    // setQaApprovalButton(2)
                } else if (response1?.data?.body?.Questions.some(question => question.qt_langflag === 1)) {
                    setSubmitForApprovalHandling(1)
                    // setQaApprovalButton(1)
                } else {
                    // setQaApprovalButton(0)
                }


            } else {
            }
            // setReason(response1?.data?.body?.QAData[0]?.q_reason)

            var qnObj = {
                boardname: {
                    label: response1.data.body.QAData[0]?.boardname,
                    value: response1.data.body.QAData[0]?.q_exctid
                },
                subject: {
                    label: response1.data.body.QAData[0]?.subject,
                    value: response1.data.body.QAData[0]?.q_sid
                },
                topicname: {
                    label: response1.data.body.QAData[0]?.topicname,
                    value: response1.data.body.QAData[0]?.q_tid
                },
                examlevel: {
                    label: response1.data.body.QAData[0]?.examlevel,
                    value: response1.data.body.QAData[0]?.q_exlid
                },
                examPaper: {
                    label: response1.data.body.QAData[0]?.exampaper,
                    value: response1.data.body.QAData[0]?.q_expid
                },
                subject_subcat: {
                    label: response1.data.body.QAData[0]?.subject_subcat,
                    value: response1.data.body.QAData[0]?.q_sscid
                }
            }
            setQnObj(qnObj)
            setselectedBoardName({
                label: response1.data.body.QAData[0]?.boardname,
                value: response1.data.body.QAData[0]?.q_exctid
            })
            handleSelectPaper({
                label: response1.data.body.QAData[0]?.exampaper,
                value: response1.data.body.QAData[0]?.q_expid
            })
            setselectedSubjectName({
                label: response1.data.body.QAData[0]?.subject,
                value: response1.data.body.QAData[0]?.q_sid.toString()
            })
            setselectedSubjectSubcatName({
                label: response1.data.body.QAData[0]?.subject_subcat,
                value: response1.data.body.QAData[0]?.q_sscid.toString()
            })
            // validation.setFieldValue("selectedSubjectName", {
            //     label: response1.data.body.QAData[0]?.subject,
            //     value: response1.data.body.QAData[0]?.q_sid.toString() + "-" + response1.data.body.QAData[0]?.q_sscid.toString()
            // })
            setselectedTopicName({
                label: response1.data.body.QAData[0]?.topicname,
                value: response1.data.body.QAData[0]?.q_tid
            })
            setselectedExamType({
                label: response1.data.body.QAData[0]?.examtype,
                value: response1.data.body.QAData[0]?.q_extid
            })
            setselectedExamLevel({
                label: response1.data.body.QAData[0]?.examlevel,
                value: response1.data.body.QAData[0]?.q_exlid
            })
            setselectedExamPaper({
                label: response1.data.body.QAData[0]?.exampaper,
                value: response1.data.body.QAData[0]?.q_expid
            })
            setselectedSubjectSubcatName({
                label: response1.data.body.QAData[0]?.subject_subcat,
                value: response1.data.body.QAData[0]?.q_sscid.toString()
            })

            // setApproveCheck(response1.data.body.QAData[0]?.q_stsid )
            if (response1.data.body.QAData[0]?.q_stsid === "2")
                setstatusQues(
                    false
                )
            else setstatusQues(
                true
            )
            if (response1.data.body.QAData[0]?.q_shuffle === 1)
                setShuffle(
                    true
                )
            else setShuffle(
                false
            )
            // if(response1.data.body.QAData[0]?.q_stsid === "3"){
            //     setApprovebutton(true)
            // }else{
            //     setApprovebutton(false)
            // }

            if (generalsetting && generalsetting?.length > 0 && generalsetting[0]?.gs_qa_approval == 1) {


                if (user.user_previleges.filter(p =>
                    Number(p.urp_smid) === constants.SubmenuList.QAApproval && (p.urp_type === constants.menuTypes.Permission1)).length === 1) {

                    if (response1.data.body.QAData[0]?.q_vstatus == "3") {
                        setApprovebutton(true)
                    } else {
                        setApprovebutton(false)
                    }

                    if (response1.data.body.QAData[0]?.q_vstatus == "5") {
                        setRejectbutton(true)
                    } else {
                        setRejectbutton(false)
                    }
                }
                else {
                    setApprovebutton(false)
                    // setRejectbutton(false)
                }
            }
            else {
                setApprovebutton(false)
             
            }

            if (response1.data.body.QAData[0]?.q_vstatus === "4") {
                setSuccessFlag(true)
            } else {
                setSuccessFlag(false)
            }

            // Set the toggle as true intially, editor will be loaded based on that, if it is not, not able to set the content becuase of the loadn=ing time of edditor
            var engOptionValues_temp = [];
            var checkedEngInputToggle_temp = {
                "0": false,
                "1": false,
                "2": false,
                "3": false,
                "4": false
            };
            var checkedEngAnswerToggle_temp = {};

            var regOptionValues_temp = [];
            var checkedRegInputToggle_temp = {
                "0": false,
                "1": false,
                "2": false,
                "3": false,
                "4": false
            };
            var checkedRegAnswerToggle_temp = {};
            var options = response1.data.body?.Options;
            var engOptions = options.filter(e => e.qa_langflag == 1); // filter only english options
            var regOptions = options.filter(e => e.qa_langflag == 2);


            var temp_eng_qaid_array = []
            var temp_reg_qaid_array = []

            setEditorEditloadEngOptions(engOptions)
            setEditorEditloadRegOptions(regOptions)
            setInitialEditorEditloadEngOptions(engOptions)
            setInitialEditorEditloadRegOptions(regOptions)
            // English options bind
            if (engOptions.length > 0) {
                for (var i = 0; i < engOptions.length; i++) {
                    temp_eng_qaid_array.push(engOptions[i]['qaid']);

                    if (engOptions[i]['qa_contentflag'] === 0) {
                        engOptionValues_temp.push(engOptions[i]['qa_name']);
                        checkedEngInputToggle_temp[i] = false;
                    } else {
                        engOptionValues_temp.push(""); // Push an empty string for other content flags
                        checkedEngInputToggle_temp[i] = true;


                        // var t = engOptionsRef?.current[i]?.current?.contentWindow;
                        // if (t !== undefined) {
                        //     t.initialContent = engOptions[i]['qa_name'];
                        //     t.updatedContent = engOptions[i]['qa_name'];
                        // }
                    }

                    if (engOptions[i]['qa_answerflag'] === 1) {
                        checkedEngAnswerToggle_temp[i] = true;
                    }
                }

                // Explicitly bind an empty value for the 4th index if engOptions has 3 elements
                if (engOptions.length === 4) {
                    engOptionValues_temp[4] = ""; // Set the 4th index to an empty string
                }
            }

            // Regionsl options bind
            if (regOptions.length > 0) {
                for (var i = 0; i < regOptions?.length; i++) {

                    temp_reg_qaid_array.push(regOptions[i]['qaid'])
                    if (regOptions[i]['qa_contentflag'] == 0) {

                        regOptionValues_temp.push(regOptions[i]['qa_name'])
                        checkedRegInputToggle_temp[i] = false
                    }
                    else {

                        regOptionValues_temp.push("")
                        checkedRegInputToggle_temp[i] = true
                        var t = regOptionsRef?.current[i]?.current?.contentWindow// ="<p>ete</p>"
                        if (t != undefined) {
                            t.initialContent = regOptions[i]['qa_name']
                            t.updatedContent = regOptions[i]['qa_name']
                        }



                    }
                    if (regOptions[i]['qa_answerflag'] == 1) {

                        checkedRegAnswerToggle_temp[i] = true

                    }



                }
                if (regOptions.length === 4) {
                    regOptionValues_temp[4] = ""; // Set the 4th index to an empty string
                }
            }

            // setCheckedRegQnEditorEble(true)
            if (qid == 0 || isClone == 1) {

                if (engOptions?.length > 0) {
                    engOptionValues_temp = ["", "", "", "",""]
                    temp_eng_qaid_array = ["", "", "", "",""]
                    // temp_eng_qaid_array = { "0": "", "1": "", "2": '', "3": '', "4": '', "5": '' }


                    setEngOptionValues(engOptionValues_temp)
                    setEngQAIDArr(temp_eng_qaid_array)


                }
                if (regOptions?.length > 0) {
                    regOptionValues_temp = ["", "", "", "",""]
                    temp_reg_qaid_array = ["", "", "", "",""]
                    setRegOptionValues(regOptionValues_temp)
                    setRegQAIDArr(temp_reg_qaid_array)

                }
            }
            if (isClone != 1) {


                if (engOptions?.length > 0) {
                    setEngOptionValues(engOptionValues_temp)
                    setEngQAIDArr(temp_eng_qaid_array)
                    setCheckedInputToggle(checkedEngInputToggle_temp)
                    setCheckedAnswerToggle(checkedEngAnswerToggle_temp)
                } else {
                    setCheckedInputToggle(checkedEngInputToggle_temp)
                }
                if (regOptions?.length > 0) {

                    setRegOptionValues(regOptionValues_temp)
                    setRegQAIDArr(temp_reg_qaid_array)
                    setCheckedRegInputToggle(checkedRegInputToggle_temp)
                    setCheckedRegAnswerToggle(checkedRegAnswerToggle_temp)
                } else {
                    setCheckedRegInputToggle(checkedRegInputToggle_temp)
                }
            }



            // Questions data bind
            var questions_arr = []
            var notes_arr = []
            var questions = response1.data.body?.Questions;

            for (var i = 0; i < questions?.length; i++) {
                if (isClone != 1) {

                    if (questions[i]['qt_contentflag'] == 1) {
                        if (questions[i]['qt_langflag'] == 1) {
                            questions_arr.push({
                                qt_langflag: questions[i]['qt_langflag'],
                                text: questions[i]['qt_text'],

                            })
                        }
                        if (questions[i]['qt_langflag'] == 2) {
                            questions_arr.push({
                                qt_langflag: questions[i]['qt_langflag'],
                                text: questions[i]['qt_text']
                            })
                        }
                    }

                    else {
                        if (questions[i]['qt_langflag'] == 1) {
                            setEngQnTxt(questions[i]['qt_text'])
                            setEngReason(questions[i]['qt_reason'])
                            // setNotes(questions[i]['qt_notes'])
                            setCheckedEngQnEditorEble(false)
                          //  setCheckedEngNotesEditorEble(false)

                        }
                        else {
                            setRegQnTxt(questions[i]['qt_text'])
                            setRegReason(questions[i]['qt_reason'])
                            // setReqNotes(questions[i]['qt_notes'])

                            setCheckedRegQnEditorEble(false)
                           // setCheckedRegNotesEditorEble(false)
                        }
                    }
                }
                // Notes data bind
                if (isClone != 1) {

                    if (questions[i]['qt_notes_contentflag'] == 1) {

                        if (questions[i]['qt_langflag'] == 1) {
                            notes_arr.push({
                                qt_langflag: questions[i]['qt_langflag'],
                                text: questions[i]['qt_notes']
                            })
                        }
                        if (questions[i]['qt_langflag'] == 2) {
                            notes_arr.push({
                                qt_langflag: questions[i]['qt_langflag'],
                                text: questions[i]['qt_notes']
                            })
                        }
                    }
                    else {

                      
                        if (questions[i]['qt_langflag'] == 1) {
                            setCheckedEngNotesEditorEble(false)
                            setNotes(questions[i]['qt_notes'])
                        }
                        else {
                            setCheckedRegNotesEditorEble(false)
                            setRegNotesTxt(questions[i]['qt_notes'])
                        }

                    }
                }
                // Reason data biind
                // if (isClone != 1) {

                //     if (questions[i]['qt_contentflag'] == 1) {

                //         if (questions[i]['qt_langflag'] == 1) {
                //             notes_arr.push({
                //                 qt_langflag: questions[i]['qt_langflag'],
                //                 text: questions[i]['qt_notes']
                //             })
                //         }
                //         if (questions[i]['qt_langflag'] == 2) {
                //             notes_arr.push({
                //                 qt_langflag: questions[i]['qt_langflag'],
                //                 text: questions[i]['qt_notes']
                //             })
                //         }
                //     }
                //     else {
                //         if (questions[i]['qt_langflag'] == 1) {

                //             setNotes(questions[i]['qt_notes'])
                //         }
                //         else {
                //             setRegNotesTxt(questions[i]['qt_notes'])
                //         }

                //     }
                // }
            }

            const eng = questions_arr.find(f =>
                Number(f.qt_langflag) === 1);

            const reg = questions_arr.find(f =>
                Number(f.qt_langflag) === 2);


            const eng_notes = notes_arr.find(f =>
                Number(f.qt_langflag) === 1);

            const reg_notes = notes_arr.find(f =>
                Number(f.qt_langflag) === 2);

            if (eng) {
                // const iframeDocument = engQnIframeRef?.current?.contentWindow;
                // iframeDocument.initialContent = eng.text
                // iframeDocument.updatedContent = eng.text
              
                setEditorEngQnTxt(eng.text)
                setInitialEditorEngQnTxt(eng.text)
                setCheckedEngQnEditorEble(true)
            } else {
                setCheckedEngQnEditorEble(false)
            }

            if (reg) {
                // const iframeDocument = regQnIframeRef?.current?.contentWindow;
                // iframeDocument.initialContent = reg.text
                // iframeDocument.updatedContent = reg.text
                setEditorRegQnTxt(reg.text)
                setInitialEditorRegQnTxt(reg.text)
                setCheckedRegQnEditorEble(true)
            } else {
                setCheckedRegQnEditorEble(false)
            }


            if (eng_notes) {
                // const iframeDocument = enNotesIframeRef?.current?.contentWindow;
                // iframeDocument.initialContent = eng_notes.text
                // iframeDocument.updatedContent = eng_notes.text
                setEditorEngNotesTxt(eng_notes.text)
                setInitialEditorEngNotesTxt(eng_notes.text)
                setCheckedEngNotesEditorEble(true)
            } else {
                setCheckedEngNotesEditorEble(false)
            }

            if (reg_notes) {
                // const iframeDocument = regNotesIframeRef?.current?.contentWindow;
                // iframeDocument.initialContent = reg_notes.text
                // iframeDocument.updatedContent = reg_notes.text
                setEditorRegNotesTxt(reg_notes.text)
                setInitialEditorRegNotesTxt(reg_notes.text)
                setCheckedRegNotesEditorEble(true)
            } else {
                setCheckedRegNotesEditorEble(false)
            }


            // handleSelectBoardName_editload({
            //     label: response1.data.body.QAData[0]?.boardname,
            //     value: response1.data.body.QAData[0]?.q_exctid
            // }, engOptionValues_temp, regOptionValues_temp)

            var selectedSubject = handleSelectPaper_editload({
                label: response1.data.body.QAData[0]?.exampaper,
                value: response1.data.body.QAData[0]?.q_expid
            })
            setselectedSubjectName([{
                label: response1.data.body.QAData[0]?.subject,
                value: response1.data.body.QAData[0]?.q_sid.toString()
            }, ...selectedSubject])
            handleSelectSubjectName_editload({
                label: response1.data.body.QAData[0]?.subject,
                value: response1.data.body.QAData[0]?.q_sid.toString()
            })
            handleSelectSubjectSubcatName_editload({
                label: response1.data.body.QAData[0]?.subject_subcat,
                value: response1.data.body.QAData[0]?.q_sscid.toString()
            })
            handleSelectTopic_editload({
                label: response1.data.body.QAData[0]?.topicname,
                value: response1.data.body.QAData[0]?.q_tid.toString()
            })
            // }
            // else {
            //     navigate("/questions")
            // }

        }
        setLoading(false);
    }

    // useEffect(() => {
    //     if (checkedEngQnEditorEble === true) {
    //         const iframeDocument = engQnIframeRef?.current?.contentWindow;
    //         iframeDocument.initialContent = questions[i]['qt_text']
    //         iframeDocument.updatedContent = questions[i]['qt_text']
    //     }
    //     if (checkedRegQnEditorEble === true) {
    //         const iframeDocument = regQnIframeRef?.current?.contentWindow;
    //         iframeDocument.initialContent = questions[i]['qt_text']
    //         iframeDocument.updatedContent = questions[i]['qt_text']
    //     }
    // }, [checkedEngQnEditorEble, checkedRegQnEditorEble])



    function handleSelectBoardName(varselectedBoardName) {

        // var filtered_exampaper_option = optionAllExamPaper.map(e => {
        //     var exctid_arr = e?.exctid?.split(',');
        //     if (exctid_arr?.length > 0) {
        //         if (exctid_arr?.includes(varselectedBoardName.value)) {
        //             return e;
        //         }
        //     }

        // })
        // filtered_exampaper_option = filtered_exampaper_option.filter(e => e != undefined);
        // setOptionExamPaper(filtered_exampaper_option)



        // setselectedExamPaper(null)
        // validation.setFieldValue("selectedExamPaper", null);
        // setselectedBoardName(varselectedBoardName);
        // getOptionCount(varselectedBoardName.value)//set option count based on the blue print
        // setselectedSubjectName(null);
        // validation.setFieldValue("selectedSubjectName", null);
        // setOptionSubjectName([])
        // setselectedTopicName(null)
        // validation.setFieldValue("selectedTopicName", null);
        // setOptionTopicName([]);

    }

    const handleSelectPaper = (selectedOption) => {
        setselectedExamPaper(selectedOption)
        setDisabledsubcat(false)
        setDisabledTopic(false)
        var filter_subject = optionAllSubjectName.filter(e => e.expid == selectedOption?.value)
        // Filter the subjects based on board and exam paper
        // var filtered_subject_option = optionAllSubjectName.map(e => {
        //     // var exctid_arr = e?.exctid?.split(',');
        //     // if (exctid_arr?.length > 0) {
        //     //     if (exctid_arr?.includes(selectedBoardName?.value) && e.cs_expid == selectedOption?.value) {
        //     //         return e;
        //     //     }
        //     // }
        //     if (e.s_expid == selectedOption?.value) {
        //         return e;
        //     }
        // })
        // filtered_subject_option = filtered_subject_option.filter(e => e != undefined);
        setOptionSubjectName(filter_subject)
        setselectedSubjectName(null)
        setSelectedLanguage(null)
        validation.setFieldValue("selectedSubjectName", null);
        validation.setFieldValue("selectedLanguage", null);
        setselectedSubjectSubcatName("")
        validation.setFieldValue('selectedSubjectSubcatName', "")
        // Filter the topics based on board and exam paper
        // var filtered_topic_option = optionAllTopicName.map(e => {
        //     var exctid_arr = e?.exctid?.split(',');
        //     if (exctid_arr?.length > 0) {
        //         if (exctid_arr?.includes(selectedBoardName?.value) && e.cs_expid == selectedOption?.value) {
        //             return e;
        //         }
        //     }

        // })
        // filtered_topic_option = filtered_topic_option.filter(e => e != undefined);
        //  setOptionTopicName(filtered_topic_option); // Comment the topic for the impact of removed topic from blur print
        setselectedTopicName(null)
        validation.setFieldValue("selectedTopicName", null);
        var lang = optionAllExamPaper.filter(e => e.expid == selectedOption?.value)
        var lang_arr
        var arr = [];
        var data1;
        if (lang && lang[0]) {
            lang_arr = lang[0]?.lang?.split(',');
        }
        if (lang_arr && lang_arr.length == 1) {
            var data = optionMasterAllLanguageData.filter(e => e.lid == Number(lang_arr[0]))
            setOptionMasterLanguageData(data)

        } else if (lang_arr && lang_arr.length == 2) {
            for (let i = 0; i < lang_arr.length; i++) {
                data1 = optionMasterAllLanguageData.filter(e => e.lid == Number(lang_arr[i]))
                arr.push(data1[0])
            }
            setOptionMasterLanguageData(arr)

        }
        // set language tab visibility
        // var suject_lang = optionAllExamPaper.map(e => {
        //     if (e.expid == selectedOption?.value) {
        //         // console.log('langa', lang_arr)
        //         console.log('e.lan', e.lang)
        //         if (e.lang?.length == 2) {
        //             console.log('=--------------------------------')
        //             var lang_arr = e.lang?.split(',');

        //             setLangArr(lang_arr);
        //             setOptionMasterLanguageData(optionMasterAllLanguageData)
        //         } else if (e?.lang == 1) {
        //             console.log('======================================')
        //             var lang_arr = e.lang[0];
        //             console.log('langarr', lang_arr)
        //             console.log('opty-----', optionMasterAllLanguageData)
        //             var data = optionMasterAllLanguageData.filter(e => Number(e.lid) == Number(lang_arr))
        //             console.log('data', data)
        //             setOptionMasterLanguageData(data)
        //             setLangArr(lang_arr);

        //         }
        //         console.log('lan', lang_arr)
        //         console.log('lan', lang_arr.length)
        //         console.log('langua', optionMasterAllLanguageData)
        setLangArr(lang_arr)
        if (lang_arr.length == 2) {
            setlanguageActiveTab("2")
            setlanguageActiveTab("1")
        } else if (lang_arr?.includes("1")) {
            setlanguageActiveTab("1")
        } else if (lang_arr?.includes("2")) {
            setlanguageActiveTab("2")
        }
        // if (lang_arr?.includes("1")) {
        //     setlanguageActiveTab("1")
        //     setlanguageActiveTab("2")
        // } else setlanguageActiveTab("2")
        //     }

        // })
    };

    // const handleSelectLanguage = (selectedOption) => {
    //     console.log('sele', selectedOption)
    //     setSelectedLanguage(selectedOption)
    //     if (selectedOption?.value == 1) {

    //         setlanguageActiveTab("1")
    //     }
    //     else {

    //         setlanguageActiveTab("2")
    //     }
    // }

    function handleSelectBoardName_editload(varselectedBoardName, varengoptions, varregoptions) {



        var filtered_paper_option = optionAllExamPaper.map(e => {
            var exctid_arr = e.exctid?.split(',');
            if (exctid_arr?.length > 0) {
                if (exctid_arr?.includes(varselectedBoardName?.value)) {
                    return e;
                }
            }

        })
        filtered_paper_option = filtered_paper_option.filter(e => e != undefined);
        getOptionCount_editload(varselectedBoardName?.value, varengoptions, varregoptions)//set option count
        setselectedBoardName(varselectedBoardName);
        setOptionExamPaper(filtered_paper_option)



    }
    function handleSelectPaper_editload(varselectedExamPaper) {
        var filter_subject = optionAllSubjectName.filter(e => e.expid == varselectedExamPaper?.value)
        return filter_subject;
    };

    const getOptionCount = (exctid) => {
        var optionCountJson = configurationOptionList.find(e => e.c_exctid == exctid)
        var optionCount = optionCountJson?.c_optionscount
        setEngOptionCount(optionCount);
        setRegOptionCount(optionCount)
        setEngOptionValues(new Array(Number(optionCount)).fill(''))
        setRegOptionValues(new Array(Number(optionCount)).fill(''))



        // const [engOptionValues, setEngOptionValues] = useState(new Array(4).fill(''));
        // const [regOptionValues, setRegOptionValues] = useState(new Array(4).fill(''));
    }

    const getOptionCount_editload = (exctid, varengoptions, varregoptions) => {

        var optionCountJson = configurationOptionList.find(e => e.c_exctid == exctid)
        var optionCount = optionCountJson?.c_optionscount
        setEngOptionCount(optionCount)
        setRegOptionCount(optionCount)
        if (optionCount > 0) {
            var diff_option_count = Number(optionCount) - (Number(varengoptions?.length) == 0 ? Number(varregoptions?.length) : Number(varengoptions?.length))
            if (diff_option_count < 0) // if our option values are 5, but in confuguration is 4, then we need an option for delete 
            {

                setShowEngDeleteIcon(true)
                setShowRegDeleteIcon(true)
            }
            var existEngOptionCount = varengoptions;
            var existRegOptionCount = varregoptions;
            for (var i = 0; i < diff_option_count; i++) { // Need to push additional options (if out current option value is 4 ,but configuration value is 5)

                var editor_index = Number(varengoptions?.length) + i
                setCheckedInputToggle((prevCheckedItems) => ({
                    ...prevCheckedItems,
                    [editor_index]: false,
                }));

                setCheckedRegInputToggle((prevCheckedItems) => ({
                    ...prevCheckedItems,
                    [editor_index]: false,
                }));

                existEngOptionCount.push("")
                existRegOptionCount.push("")

                const newRef = createRef();
                engOptionsRef.current.push(newRef); // Need to push the ref for editor
                regOptionsRef.current.push(newRef);


            }

            setEngOptionValues(existEngOptionCount)
            setRegOptionValues(existRegOptionCount)

            // setCheckedInputToggle((prevCheckedItems) => ({
            //     ...prevCheckedItems,
            //     [4]: false,
            // }));

            // setCheckedRegInputToggle((prevCheckedItems) => ({
            //     ...prevCheckedItems,
            //     [4]: false,
            // }));

        }

        // const [engOptionValues, setEngOptionValues] = useState(new Array(4).fill(''));
        // const [regOptionValues, setRegOptionValues] = useState(new Array(4).fill(''));
    }

    function handleSelectTopicName(varselectedTopicName) {
        setselectedTopicName(varselectedTopicName);
    }

    function handleSelectSubjectName(varselectedSubjectName) {

        var filter_subcat = optionAllSubjectSubcatName.filter(e => e.ssc_sid == varselectedSubjectName?.value)
        setOptionSubjectSubcatName(filter_subcat)
        var filter_topic = optionAllTopicName.filter(e => e.subjectid == varselectedSubjectName?.value)
        setOptionTopicName(filter_topic);
        if (filter_topic.length == 0) {
            setDisabledTopic(true)
        } else {
            setDisabledTopic(false)
        }
        // Filter the topics based on board and exam paper
        // var filtered_paper_topic_option = optionAllTopicName.map(e => {
        //     var exctid_arr = e?.exctid?.split(',');
        //     if (exctid_arr?.length > 0) {
        //         if (exctid_arr?.includes(selectedBoardName?.value) && e.cs_expid == selectedExamPaper?.value) {
        //             return e;
        //         }
        //     }

        // })
        // filtered_paper_topic_option = filtered_paper_topic_option.filter(e => e != undefined);


        // const [sid, sscid] = varselectedSubjectName.value?.split('-');
        //  var filtered_topic_option = filtered_paper_topic_option.filter(e => e.subjectid == sid && e.subcategoryid == sscid);

        // var filtered_topic_option = optionAllTopicName.filter(e => e.subjectid == sid && e.subcategoryid == sscid); //impact of removed topic from blue print
        setselectedSubjectName(varselectedSubjectName);
        //   filtered_topic_option = filtered_topic_option.filter(e => e != undefined);
        // filtered_topic_option = filtered_topic_option.filter(e => e != undefined);
        // setOptionTopicName(filtered_topic_option);
        setselectedTopicName(null)
        validation.setFieldValue("selectedTopicName", null);


        // const data = optionAllSubjectSubcatName.filter(e => e.ssc_sid == selectedOption?.value)

        // setAllSubjectSubcatList(data)
        // setselectedSubjectSubcat("")
        // validation.setFieldValue("selectedSubjectSubcat", "")
        // console.log('=--=--=-=-', data)
        // console.log('data', data?.length)
        if (filter_subcat?.length === 0) {
            setselectedSubjectSubcatName("")
            validation.setFieldValue('selectedSubjectSubcatName', "")
            setDisabledsubcat(true)
        } else {
            setDisabledsubcat(false)
        }


    }
    function handleSelectSubjectSubcatName(varselectedSubjectName) {
        setselectedSubjectSubcatName(varselectedSubjectName)
    }
    // For language tab visibility and topic name load

    function handleSelectSubjectName_editload(varselectedSubjectName) {
        const sid = varselectedSubjectName.value;
        setselectedSubjectName(varselectedSubjectName);

    }
    function handleSelectSubjectSubcatName_editload(varselectedSubjectName) {
        if (varselectedSubjectName?.value == 0) {
            setDisabledsubcat(true)
        }
        const sscid = varselectedSubjectName.value;
        // var filtered_topic_option = optionTopicName.filter(e => e.subcategoryid == sscid);
        setselectedSubjectSubcatName(varselectedSubjectName)
        // setselectedSubjectName(varselectedSubjectName);
        // setselectedSubjectSubcatName(varselectedSubjectName);
        // filtered_topic_option = filtered_topic_option.filter(e => e != undefined);
        // console.log('filter', filtered_topic_option)
        // setOptionTopicName(filtered_topic_option);

    }
    function handleSelectTopic_editload(varselectedSubjectName) {
        if (varselectedSubjectName?.value == 0) {
            setDisabledTopic(true)
        }
        // const sscid = varselectedSubjectName.value;
        // var filtered_topic_option = optionTopicName.filter(e => e.subcategoryid == sscid);
        setselectedTopicName(varselectedSubjectName)
        // setselectedSubjectName(varselectedSubjectName);
        // setselectedSubjectSubcatName(varselectedSubjectName);
        // filtered_topic_option = filtered_topic_option.filter(e => e != undefined);
        // console.log('filter', filtered_topic_option)
        // setOptionTopicName(filtered_topic_option);

    }



    function handleSelectedExamType(selectedExamType) {
        setselectedExamType(selectedExamType);
    }

    function handleSelectedExamLevel(varselectedExamLevel) {
        setselectedExamLevel(varselectedExamLevel);
    }


    const changeLangugeTab = tab => {
        if (languageActiveTab !== tab) {
            setlanguageActiveTab(tab);
        }
    };


    const handleCheckboxInputValue = (index) => {
        setCheckedInputToggle((prevCheckedItems) => ({
            ...prevCheckedItems,
            [index]: !prevCheckedItems[index],
        }));
    };
    const handleCheckboxRegInputValue = (index) => {
        setCheckedRegInputToggle((prevCheckedItems) => ({
            ...prevCheckedItems,
            [index]: !prevCheckedItems[index],
        }));
    };
    const handleCheckboxAnsValue = (index) => {
        setCheckedAnswerToggle((prevCheckedItems) => ({
            // ...prevCheckedItems,
            [index]: !prevCheckedItems[index],
        }));
    };
    const handleCheckboxRegAnsValue = (index) => {
        setCheckedRegAnswerToggle((prevCheckedItems) => ({
            // ...prevCheckedItems,
            [index]: !prevCheckedItems[index],
        }));
    };
    const handleEngOptionInputChange = (index, value) => {
        let data = value ? value.replace(constants.regexValidation.removeOtherLanguage, "") : '';
        setEngOptionValues((prevInputValues) => {
            const newInputValues = [...prevInputValues];
            newInputValues[index] = data;
            return newInputValues;
        });
    };
    const handleRegOptionInputChange = (index, value) => {
        setRegOptionValues((prevInputValues) => {
            const newInputValues = [...prevInputValues];
            newInputValues[index] = value;
            return newInputValues;
        });
    };


    const handleSaveClick = async (data1) => {
        
        var data = 1
        setSaveDisable(data)
        const user = getLoggedInUser()
        if (qid != 0) {


            const approve_status_array = editLoadQaData.map(item => item.qt_approve_status);
            console.log('approbe', approve_status_array)
        }

        // engOptionValues = [
        //     "dsfdssd",
        //     "",
        //     "",
        //     ""
        // ]
        // checkedInputToggle = {
        //     "1": true,
        //     "2": false
        // }
        // checkedAnswerToggle = {
        //     "1": true
        // }


        var optionsArray = []
        // console.log('-------', langArr)
        //Frame eng language options
        if (langArr?.includes("1")) {
            for (var i = 0; i < engOptionValues?.length; i++) {
                var optionJson = {};
                if (checkedInputToggle[i] == true) {
                    optionJson['qa_contentflag'] = 1;
                    optionJson['qa_name'] = editorEditloadEngOptions[i]['qa_name'] //engOptionsRef.current[i]?.current?.contentWindow?.updatedContent


                }
                else {
                    optionJson['qa_name'] = engOptionValues[i];
                    optionJson['qa_contentflag'] = 0;
                }

                if (checkedAnswerToggle[i] == true) {
                    optionJson['qa_answerflag'] = 1;
                }
                else {
                    optionJson['qa_answerflag'] = 0;
                }
                optionJson['qa_langflag'] = 1;
                if (engQAIDArr.length > 0) {
                    optionJson['qaid'] = engQAIDArr[i];
                } else {
                    optionJson['qaid'] = '';
                }
                if (optionJson['qa_name'] != "" && optionJson['qa_name'] != undefined)
                    optionsArray.push(optionJson);
            }
        }

        // Frmae regional language options
        if (langArr?.includes("2")) {
            for (var i = 0; i < regOptionValues?.length; i++) {
                var optionJson = {};
                if (checkedRegInputToggle[i] == true) {
                    optionJson['qa_contentflag'] = 1;
                    optionJson['qa_name'] =  editorEditloadRegOptions[i]['qa_name'] //regOptionsRef.current[i]?.current?.contentWindow?.updatedContent


                }
                else {
                    optionJson['qa_name'] = regOptionValues[i];
                    optionJson['qa_contentflag'] = 0;
                }

                if (checkedRegAnswerToggle[i] == true) {
                    optionJson['qa_answerflag'] = 1;
                }
                else {
                    optionJson['qa_answerflag'] = 0;
                }
                optionJson['qa_langflag'] = 2;
                if (regQAIDArr.length > 0) {
                    optionJson['qaid'] = regQAIDArr[i];
                } else {
                    optionJson['qaid'] = '';
                }
                if (optionJson['qa_name'] != "" && optionJson['qa_name'] != undefined)
                    optionsArray.push(optionJson);
            }

        }


        const engQnEditorValue = editorEngQnTxt //engQnIframeRef?.current?.contentWindow?.updatedContent;
        const regQnEditorValue = editorRegQnTxt//regQnIframeRef?.current?.contentWindow?.updatedContent;
        const engNotesEditorValue =  editorEngNotesTxt//enNotesIframeRef?.current?.contentWindow?.updatedContent;
        const regNotesEditorValue = editorRegNotesTxt //regNotesIframeRef?.current?.contentWindow?.updatedContent;


        var questionArray = [];
        var engQnJson = {};
        var regQnJson = {};


        if (checkedEngQnEditorEble == true) {
            engQnJson['qt_contentflag'] = 1;
            engQnJson['qt_text'] = engQnEditorValue;
        }
        else {
            engQnJson['qt_contentflag'] = 0;
            // engQnJson['qt_notes_contentflag'] = 0;
            engQnJson['qt_text'] = engQnTxt;
            // engQnJson['qt_notes'] = notes;

        }
        engQnJson['qt_langflag'] = 1;

        if (checkedEngNotesEditorEble == true) {
            engQnJson['qt_notes_contentflag'] = 1;
            engQnJson['qt_notes'] = engNotesEditorValue;
        }
        else {
            engQnJson['qt_notes_contentflag'] = 0;
            engQnJson['qt_notes'] = notes;

        }
        // engQnJson['qt_langflag'] = 1;
        if (checkedRegQnEditorEble == true) {
            regQnJson['qt_contentflag'] = 1;
            regQnJson['qt_text'] = regQnEditorValue;
            // reqQnJson['qt_notes'] = notes;

        }
        else {
            regQnJson['qt_contentflag'] = 0;
            // regQnJson['qt_notes_contentflag'] = 0;
            regQnJson['qt_text'] = regQnTxt;
            // regQnJson['qt_notes'] = reqnotes;

        }


        regQnJson['qt_langflag'] = 2;
        if (checkedRegNotesEditorEble == true) {
            regQnJson['qt_notes_contentflag'] = 1;
            regQnJson['qt_notes'] = regNotesEditorValue;
            // reqQnJson['qt_notes'] = notes;

        }
        else {
            regQnJson['qt_notes_contentflag'] = 0;
            // regQnJson['qt_text'] = regQnTxt;
            regQnJson['qt_notes'] = regNotesTxt;

        }
        regQnJson['qt_langflag'] = 2;

    
        var eng_approve_status = 0
        var reg_approve_status = 0



        engQnJson['qt_approve_status'] = 0
        regQnJson['qt_approve_status'] = 0

        if(approveStatusArray.length > 0)
        {
            if(approveStatusArray.length == 1)
            {
                engQnJson['qt_approve_status'] =  approveStatusArray[0] != 0 ? approveStatusArray[0] :0
                eng_approve_status = approveStatusArray[0] != 0 ? approveStatusArray[0] :0

                regQnJson['qt_approve_status'] =  approveStatusArray[0]!= 0 ? approveStatusArray[0] :0
                reg_approve_status =approveStatusArray[0]!= 0 ? approveStatusArray[0] :0
            }

           
            if(approveStatusArray.length > 1)
            {
                engQnJson['qt_approve_status'] =  approveStatusArray[0] != 0 ? approveStatusArray[0] :0
                eng_approve_status = approveStatusArray[0] != 0 ? approveStatusArray[0] :0

                regQnJson['qt_approve_status'] =  approveStatusArray[1]!= 0 ? approveStatusArray[1] :0
                reg_approve_status =approveStatusArray[1]!= 0 ? approveStatusArray[1] :0
            }
        }

        if (engQnJson['qt_text'] != "" && data1?.approval_lang== 1) {
            engQnJson['qt_reason'] = reason !== '' && reason !== undefined && reason !== null ? reason : ''
          
           // engQnJson['qt_approval_flag'] = data1?.qt_approval_flag == 1 ? data1?.qt_approval_flag : 0
            engQnJson['qt_approve_status'] = data1?.qt_approval_flag != 0 && data1?.approval_lang== 1 ? data1?.qt_approval_flag : 0
            eng_approve_status = data1?.qt_approval_flag != 0 && data1?.approval_lang== 1 ? data1?.qt_approval_flag : 0
        }
        if (regQnJson['qt_text'] != "" && data1?.approval_lang== 2) {
            
            regQnJson['qt_reason'] = reason !== '' && reason !== undefined && reason !== null ? reason : ''
         //   regQnJson['qt_approval_flag'] = data1?.qt_approval_flag == 2 ? 1 : 0

            regQnJson['qt_approve_status'] = data1?.qt_approval_flag != 0 && data1?.approval_lang == 2 ? data1?.qt_approval_flag : 0

            reg_approve_status =  data1?.qt_approval_flag != 0 && data1?.approval_lang == 2 ? data1?.qt_approval_flag : 0
        }



        if (engQnJson['qt_text'] != "" && engQnJson['qt_text'] != undefined || (engQnJson['qt_notes'] != "" && engQnJson['qt_notes'] != undefined))
            questionArray.push(engQnJson);
        // if (engQnJson['qt_notes'] != "" && engQnJson['qt_notes'] != undefined)
        //     questionArray.push(engQnJson)
        if (regQnJson['qt_text'] != "" && regQnJson['qt_text'] != undefined || (regQnJson['qt_notes'] != "" && regQnJson['qt_notes'] != undefined))
            questionArray.push(regQnJson);


        const approve_status_array = questionArray.map(item => item.qt_approval_flag);
        console.log('======================', questionArray)
        console.log('appp', approve_status_array)
        // if (englishBasedTab == 1 && regionalBasedTab == 1) {

        // }else{

        // }


        // if (regQnJson['qt_notes'] != "" && regQnJson['qt_notes'] != undefined)
        //     questionArray.push(regQnJson);

        // Question validation
        var checkQn = questionArray.filter(e => e.qt_text != "")

        // if (checkQn?.length != langArr?.length) {
        //     toast.error("Please enter the question")
        //     return false
        // }

   

        var checkEngQn  = questionArray.filter(e=>e.qt_langflag ==  1)
        var checkRegQn  = questionArray.filter(e=>e.qt_langflag ==  2)

        // var checkEngOptions  = questionArray.find(e=>e.qt_langflag ==  1)
        // var checkRegOptions  = questionArray.find(e=>e.qt_langflag ==  2)
        var englang_option_count = optionsArray.filter(e => e.qa_langflag == 1 && e.qa_text != "")
        var reglang_option_count = optionsArray.filter(e => e.qa_langflag == 2 && e.qa_text != "")
       

        if (checkEngQn.length == 1) {
          
            if (englang_option_count?.length < 4) {
                toast.error("Please enter the english options")
                return false
            }
        }

        if (checkRegQn.length == 1) {        

            if (reglang_option_count?.length < 4) {
                toast.error("Please enter the regional options")
                return false
            }
        }

        if (englang_option_count.length >= 1) {
          
            if (checkEngQn.length == 0) {
                toast.error("Please enter the english question")
                return false
            }
        }

        if (reglang_option_count.length >= 1) {        
          
            if (checkRegQn.length == 0) {        
                toast.error("Please enter the regional question")
                return false
            }
        }


        if (langArr) {
            if (langArr?.length == 2) {
                if (langArr?.includes("1") || langArr?.includes("2")) {
                    var englang_option_count = optionsArray.filter(e => e.qa_langflag == 1 && e.qa_text != "")
                    var reglang_option_count = optionsArray.filter(e => e.qa_langflag == 2 && e.qa_text != "")

                    // if (!regQnTxt || engQnTxt && englang_option_count?.length != engOptionValues?.length) {
                    // if (englang_option_count?.length !== (Number(engOptionValues?.length))) {
                    //     toast.error("Please enter the English options")
                    //     return false
                    // }
                    // }

                    // if (reglang_option_count?.length != regOptionValues?.length && Number(languageActiveTab) === 2 || reglang_option_count?.length != regOptionValues?.length && regQnTxt) {
                    //     toast.error("Please enter the Regional options")
                    //     return false
                    // }

                    // Get all values from the object
                    const values = Object.values(checkedAnswerToggle);

                    // Check if any value is true
                    const hasTrue = values.some(value => value === true);

                    // Get all values from the object
                    const values1 = Object.values(checkedRegAnswerToggle);

                    // Check if any value is true
                    const hasTrue1 = values1.some(value => value === true);

                    if (engQnTxt && Object.keys(checkedAnswerToggle).length === 0 || Object.keys(checkedRegAnswerToggle).length === 0 && Number(languageActiveTab) === 2 || Object.keys(checkedRegAnswerToggle).length === 0 && regQnTxt || engQnTxt && !hasTrue || regQnTxt && !hasTrue1) {
                        toast.error('Please select correct answer')
                        return false
                    }
                }
            } else {
                var englang_option_count = optionsArray.filter(e => e.qa_langflag == 1 && e.qa_text != "")
                var reglang_option_count = optionsArray.filter(e => e.qa_langflag == 2 && e.qa_text != "")
                if (langArr?.includes("1")) {

                    // if ((englang_option_count?.length != engOptionValues?.length)) {
                    //     toast.error("Please enter the options")
                    //     return false
                    // }

                    // Get all values from the object
                    const values = Object.values(checkedAnswerToggle);

                    // Check if any value is true
                    const hasTrue = values.some(value => value === true);

                    if (Object.keys(checkedAnswerToggle).length === 0 || !hasTrue) {
                        toast.error('Please select correct answer')
                        return false
                    }

                } else {
                    // console.log('==========>', reglang_option_count, regOptionValues)
                    // if ((reglang_option_count?.length != (regOptionValues?.length) - 1)) {
                    //     toast.error("Please enter the options")
                    //     return false
                    // }

                    // Get all values from the object
                    const values = Object.values(checkedRegAnswerToggle);

                    // Check if any value is true
                    const hasTrue = values.some(value => value === true);

                    if (Object.keys(checkedRegAnswerToggle).length === 0 || !hasTrue) {
                        toast.error('Please select correct answer')
                        return false
                    }
                }
            }
        }


        //Check any of the option is mark as answer 
        var checkAns = optionsArray.filter(e => e.qa_answerflag == 1)

        // if (checkAns?.length != langArr?.length) {
        //     toast.error("Please mark the answer")
        //     return false
        // }

        var isClone = localStorage.getItem('isClone');
        var qts;
        var vts;
        if (generalsetting && generalsetting.length > 0 && generalsetting[0].gs_qa_approval == 1) {

            if (qid == 0 || isClone == '1') {


                if (user.user_previleges.filter(p =>
                    Number(p.urp_mid) === constants.mainMenuList.QA && (p.urp_type === constants.menuTypes.Special)).length === 1) {
                    qts = 1
                    vts = 3

                }
                else {
                    qts = 3
                    vts = 3

                }
            } else {
                if (approveQues == 3) {
                    qts = 3
                    vts = 3
                }
                else {
                    if (statusQues === true) {
                        qts = 1
                        vts = 3

                    } else {
                        qts = 2
                        vts = 3

                    }
                }
            }

        } else {
            if (qid == 0 || isClone == '1') {
                qts = 1;
                vts = 4;
            } else {
                if (statusQues === true) {
                    qts = 1
                    vts = 4

                } else {
                    qts = 2
                    vts = 4

                }
            }
        }
        var data;
        var response1;


        var q_approval_status = ""
        if(langArr?.includes("1"))
        {
            q_approval_status = eng_approve_status.toString()
        }
        if(langArr?.includes("2"))
        {
            q_approval_status = q_approval_status+reg_approve_status.toString()
        }
// if update then need to change the status as pending

        // if(data1?.qt_approval_flag == 0)
        // {
        //     if(langArr?.includes("1"))
        //     {
        //         q_approval_status = "0"
        //     }
        //     if(langArr?.includes("2"))
        //     {
        //         q_approval_status = "00"
        //     }
        // }
            
        if (qid == 0) {
            const q_sid = selectedSubjectName != null ? selectedSubjectName.value : 0;
            const q_sscid = selectedSubjectSubcatName != null ? selectedSubjectSubcatName.value : 0;
            data = {
                "user_id": Number(user.uid),
                "process_type": qid == 0 || isClone == '1' ? 'save' : 'update',
                "qid": isClone == '1' ? 0 : Number(qid),

                "q_exctid": Number(selectedBoardName?.value) || 0,
                "q_sid": Number(q_sid),
                "q_sscid": Number(q_sscid) || 0,
                "q_tid": Number(selectedTopicName?.value) || 0,
                "q_exlid": Number(selectedExamLevel?.value) || 0,
                "q_extid": Number(selectedExamType?.value),
                "q_ex_paper_id": Number(selectedExamPaper?.value),
                "q_shuffle": Number(shuffle),
                "q_approval_flag": 0,
                "q_approval_status": q_approval_status,
                "q_stsid": qts,
                "q_options": optionsArray,
                "q_questions": questionArray,
                "q_vstatus": vts,
                "q_reason": reason !== '' && reason !== undefined && reason !== null ? reason : ''
            }

        } else {
            const [q_sid, q_sscid] = selectedSubjectName != null ? selectedSubjectName.value?.split('-') : [0, 0];
            data = {
                "user_id": Number(user.uid),
                "process_type": qid == 0 || isClone == '1' ? 'save' : 'update',
                "qid": isClone == '1' ? 0 : Number(qid),

                "q_exctid": Number(selectedBoardName?.value) || 0,
                "q_sid": Number(q_sid),
                "q_sscid": Number(q_sscid) || 0,
                "q_tid": Number(selectedTopicName?.value) || 0,
                "q_exlid": Number(selectedExamLevel?.value) || 0,
                "q_extid": Number(selectedExamType?.value),
                "q_ex_paper_id": Number(selectedExamPaper?.value),
                "q_shuffle": Number(shuffle),
                "q_approval_flag":0,

                // data1?.approval_flag
                "q_approval_status":q_approval_status,
                "q_stsid": qts,
                "q_options": optionsArray,
                "q_questions": questionArray,
                "q_vstatus": vts,
                "q_reason": (reason !== null && reason !== undefined && reason !== '') ? reason : ''
            }
        }

        // if (!rejectbutton) {

        //     if (qaApprovalButton == 1) {
        //         if (langArr && langArr.length == 2) {
        //             if (optionsArray && optionsArray.length == 8 || optionsArray.length == 9 || optionsArray.length == 10) {
        //                 const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }

        //                 response1 = await axios.post(url.BASE_URL + 'qa/saveQA', data, config)

        //             } else {
        //                 toast.error('Please fill two tabs', { autoClose: 2000 });

        //             }
        //         } else if (langArr && langArr.length == 1) {
        //             if (optionsArray && optionsArray.length == 4 || optionsArray.length == 5) {
        //                 const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }

        //                 response1 = await axios.post(url.BASE_URL + 'qa/saveQA', data, config)

        //             } else {
        //                 toast.error('Please fill two tabs', { autoClose: 2000 });

        //             }
        //         }

        //     } else {
                const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }

                response1 = await axios.post(url.BASE_URL + 'qa/saveQA', data, config)
            // }



            // if (langArr && langArr.length == 2) {
            //     if (optionsArray && optionsArray.length == 8 || optionsArray.length == 9 || optionsArray.length == 10) {
            // const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }

            // response1 = await axios.post(url.BASE_URL + 'qa/saveQA', data, config)

            // } else {
            // toast.error('Please fill two tabs', { autoClose: 2000 });

            // }
            // } else if (langArr && langArr.length == 1) {
            // if (optionsArray && optionsArray.length == 4 || optionsArray.length == 5) {
            // const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }

            // response1 = await axios.post(url.BASE_URL + 'qa/saveQA', data, config)

            // } else {
            // toast.error('Please fill two tabs', { autoClose: 2000 });

            // }
            // }
        // } else {
        //     const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }

        //     response1 = await axios.post(url.BASE_URL + 'qa/saveQA', data, config)
        // }


        // const response1 = await axios.post(url.BASE_URL + 'qa/saveQA', data, config)
        if (response1 && response1.statusFlag === 3) { // user validation
            toast.error(response1.message, { autoClose: 2000 });
            localStorage.removeItem("authUser");
            sessionStorage.removeItem("SESSION_KEY")
            window.location.reload()
            return
        }
        if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
            const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
            if (refresh) {
                if (Number(refresh) === constants.invalidRefreshToken) {
                    logoutUser()
                } else {
                    handleSaveClick() // calling same function again getting refresh token
                }
            }
        }

        if (response1 && response1.data && response1.data.body) {

            toast.success(response1.data.body.message, { autoClose: 2000 });
            if (saveClone == 1) {
                setNotes("")
                setEngQnTxt("")

                setRegNotesTxt("")
                setRegQnTxt("")
                setEngOptionValues(["", "", "", "",""])

                // setEngOptionValues([])
                // setEngQAIDArr([])
                // setCheckedInputToggle("")
                setCheckedAnswerToggle("")
                setRegOptionValues(["", "", "", "",""])
                // setRegQAIDArr([])
                setCheckedRegInputToggle("")
                setCheckedRegAnswerToggle("")
                setCheckedInputToggle("")
                setCheckedAnswerToggle("")
                setCheckedEngQnEditorEble(false)
                setCheckedRegQnEditorEble(false)
                setCheckedEngNotesEditorEble(false)
                setCheckedRegNotesEditorEble(false)



                // editLoadQA(response1.data.body.updated_qid)
                localStorage.setItem('isClone', '0')
                // navigate(
                //     `/questions`
                // )
                // qid = 0;
            }
            else {
                setTimeout(() => {
                    if (Number(localStorage.getItem('isClone')) === 3) {
                        navigate(
                            `/list-question-approval`
                        )
                    } else {
                        navigate(
                            `/questions`
                        )
                    }
                }, 1000);

            }
        }
        // } else {
        //     toast.error('Please fill all tabs', { autoClose: 2000 });

        // }
        engOptionsRef.current.map(
            ref => {
                console.log(ref?.current?.contentWindow?.updatedContent, 'ref')
            }
        );
        regOptionsRef.current.map(
            ref => {
                console.log(ref?.current?.contentWindow?.updatedContent, 'ref')
            }
        );
        // You can perform additional actions here
    };

    const clearValues = () =>
    {
        setNotes("")
        setEngQnTxt("")

        setRegNotesTxt("")
        setRegQnTxt("")
        setEngOptionValues(["", "", "", "",""])

        // setEngOptionValues([])
        // setEngQAIDArr([])
        // setCheckedInputToggle("")
        setCheckedAnswerToggle("")
        setRegOptionValues(["", "", "", "",""])
        // setRegQAIDArr([])
        setCheckedRegInputToggle("")
        setCheckedRegAnswerToggle("")
        setCheckedInputToggle("")
        setCheckedAnswerToggle("")
        setCheckedEngQnEditorEble(false)
        setCheckedRegQnEditorEble(false)
        setCheckedEngNotesEditorEble(false)
        setCheckedRegNotesEditorEble(false)
    }
    useEffect(() => {
       
        // if(store?.masterlist !)
        if (store.masterlist != undefined) {
            //     if (store.masterlist && store.masterlist.board_categoryList) {
            //         var boardList = store.masterlist.board_categoryList.filter(e => e.c_exctid != 0)
            //         setOptionBoardName(boardList)
            //     }

            //     if (store.masterlist && store.masterlist.conf_subject_subcategoryList) {
            //         setOptionAllSubjectName(store.masterlist.conf_subject_subcategoryList)
            //         setOptionSubjectName(store.masterlist.conf_subject_subcategoryList)
            //     }

            //     if (store.masterlist && store.masterlist.examPaperList) {
            //         setOptionAllExamPaper(store.masterlist.examPaperList)
            //         setOptionExamPaper(store.masterlist.examPaperList)
            //     }
            //     if (store.masterlist && store.masterlist.topicList) {
            //         setOptionAllTopicName(store.masterlist.topicList)
            //         setOptionTopicName(store.masterlist.topicList)
            //     }
            if (store.masterlist && store.masterlist.generalSettings) {
                setGeneralSetting(store.masterlist.generalSettings)
            }
            if (store.masterlist && store.masterlist.userBasedApproveList) {
                const data = store.masterlist.userBasedApproveList
                const filtered_approve = data.filter(e => e.uid == user?.uid)
                setUserBasedApproveList(filtered_approve)
            }

            if (store.masterlist && store.masterlist.examLevelList)
                setOptionExamLevel(store.masterlist.examLevelList)
            if (store.masterlist && store.masterlist.examTypeList)
                setOptionExamType(store.masterlist.examTypeList)
            if (store.masterlist && store.masterlist.configurationList)
                setConfigurationOptionList(store.masterlist.configurationList)

            // set the default exam type like preliminary
            var defaultExamType = store?.masterlist?.examTypeList?.find(e => e.ext_default == 1);
            setselectedExamType({
                label: defaultExamType?.typename,
                value: defaultExamType?.typeid
            })
        }






        if (optionAllSubjectName?.length > 0) {
           
            if (qid != "0" && qid != null && qid != undefined) {
                setRecordId(qid)
                // setDisabledsubcat(true)
                // setDisabledTopic(true)
                editLoadQA(qid)
            }
            else {
                setShuffle(true)
                setCheckedEngQnEditorEble(false)
                setCheckedRegQnEditorEble(false)
                setCheckedRegNotesEditorEble(false)
                setCheckedEngNotesEditorEble(false)
                setCheckedInputToggle({
                    "0": false,
                    "1": false,
                    "2": false,
                    "3": false,
                    "4": false
                });
                setCheckedRegInputToggle({
                    "0": false,
                    "1": false,
                    "2": false,
                    "3": false,
                    "4": false
                });
            }

        }


        // }, 10000);



    }, [store.masterlist, optionAllSubjectName])

    const handleClose = () => {

        if (Number(localStorage.getItem('isClone')) === 3) {
            navigate(
                `/list-question-approval`
            )
        } else {
            navigate(
                `/questions`
            )
        }
        // navigate("/questions");
    }



    const rejectToggle = () => {
        setRejectModal(prev => !prev);
    };
    const inputRef = useRef(null);
    const rejectValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            reason: (reason) || ''
        },
        validationSchema: Yup.object({
            reason: Yup.string().required("Please Enter Reason")
        }),
        onSubmit: (values) => {
            setLoading(true);
            handleRejectClick()
        },
    });

    useEffect(() => {
        if (rejectModal && inputRef.current && inputFocus) {
            inputRef.current.focus();
            setInputFocus(false);
        }
    }, [rejectModal, inputFocus]);

    useEffect(() => {
        if (rejectModal) {
            setInputFocus(true);
        }
    }, [rejectModal]);

    useEffect(() => {
        if (!rejectModal && rejectbutton === false) {
            reserForm()
        }
    }, [rejectModal]);

    const reserForm = () => {
        // setRejectModal(false)
        rejectValidation.setFieldValue('reason', '')
        rejectValidation.setFieldTouched('reason', false)
    }

    const fetchNextRecord = (currentId, records) => {

        // Find the index of the current record
        const currentIndex = records.findIndex(record => Number(record.qid) === Number(currentId));

        // Check if the current index is valid and there is a next record
        if (currentIndex >= 0 && currentIndex < records.length - 1) {
            return records[currentIndex + 1]; // Return the next record
        } else {
            return null; // No next record available
        }
    };

    const fetchPreviousRecord = (currentId, records) => {

        // Find the index of the current record
        const currentIndex = records.findIndex(record => Number(record.qid) === Number(currentId));

        // Check if the current index is valid and there is a next record
        if (currentIndex > 0) {
            return records[currentIndex - 1]; // Return the next record
        } else {
            return null; // No next record available
        }
    };

    const Next = () => {
      
        setQaListStatus(true)
        clearValues()
        if (qid != "0" && qid != null && qid != undefined) {
            const nextRecord = fetchNextRecord(recordId, qaList);

            if (nextRecord) {
                console.log("Next Record:", nextRecord);
                setLoading(true)
                setRecordId(nextRecord.qid)
                navigate(`/add-questions/${nextRecord.qid}`);
                editLoadQA(nextRecord.qid)
            } else {
                console.log("No next record available.");
                return null;
            }
        }
    }

    const Previous = () => {
        setQaListStatus(true)
 
        clearValues()
        
        // if (qid != "0" && qid != null && qid != undefined) {
        const nextRecord = fetchPreviousRecord(recordId, qaList);

        if (nextRecord) {
            console.log("Next Record:", nextRecord);
            setLoading(true)
            setRecordId(nextRecord.qid)
            navigate(`/add-questions/${nextRecord.qid}`);
            editLoadQA(nextRecord.qid)
        } else {
            console.log("No previous record available.");
            return null;
        }
        // }
    }

    async function GetQAList(offset) {

        const user = getLoggedInUser()
        var data;

        data = {
            "user_id": Number(user.uid),
            "q_sscid": Number(0),
            "q_tid": Number(0),
            "q_stsid": Number(0),
            "q_exctid": Number(0),
            "q_sid": Number(0),
            "q_ex_paper_id": Number(0),
            "q_approvetests": Number(0),
            "lang_id": Number(0),
            "q_vstatus": 3,
            // "offset": offset,
            "offset": 0,
            "q_createdBy": 0,

            "searchData": "",
            "limit": 10,
            // "filter_user_id": filter_user_id?.uid ? 0 : Number(user?.uid),
            "filter_user_id": 0,
            "exam_level_id": 0,
            "q_approval_flag": 1
        }



        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
        const response1 = await axios.post(url.BASE_URL + 'qa/getQAPendingList', data, config)
        if (response1 && response1.statusFlag === 3) { // user validation
            toast.error(response1.message, { autoClose: 2000 });
            localStorage.removeItem("authUser");
            sessionStorage.removeItem("SESSION_KEY")
            window.location.reload()
            return
        }
        if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
            const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
            if (refresh) {
                if (Number(refresh) === constants.invalidRefreshToken) {
                    logoutUser()
                } else {
                    GetQAList(offset) // calling same function again getting refresh token
                }
            }
        }
        if (response1 && response1.data && response1.data.body) {
            setQAList(response1.data.body.QAList)
            console.log('response1.data.body.QAList', response1.data.body.QAList);
            localStorage.setItem('QAApprovelList', JSON.stringify(response1.data.body.QAList))
            return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
        } else {
            return { statusFlag: 2, message: 'Something went wrong' }
        }
    }

    // useEffect(() => {
    //     if (qaListStatus === false) {
    //         GetQAList()
    //     }
    // }, [qaListStatus]);

    return (
        <React.Fragment>
            <ApproveModal
                show={approveModal}
                onDeleteClick={handleApproveClick}
                onCloseClick={() => setApproveModal(false)}
            />

            <SubmitConfirmModal
                show={submitConfirmModal}
                onDeleteClick={()=>handleSubmitApprove(1)}
                onCloseClick={() => setSubmitConfirmModal(false)}
            />
              <RegSubmitConfirmModal
                show={regSubmitConfirmModal}
                onDeleteClick={()=>handleSubmitApprove(2)}
                onCloseClick={() => setRegSubmitConfirmModal(false)}
            />
            <div className="page-content">
                <Container fluid={true}>
                    {/* <Breadcrumbs breadcrumbItem="Questions" /> */}
                    {
                        isLoading ? <Spinners setLoading={setLoading} />
                            : null}
                    <Row>
                        <Col lg="12">
                            <Form onSubmit={(e) => {
                                if (e.nativeEvent.submitter.id == 'save') setSaveClone(0)
                                else setSaveClone(1)
                                e.preventDefault();
                                validation.handleSubmit(e.nativeEvent.submitter.id);
                                return false;
                            }}
                            >
                                <div className="">
                                    <Card className="crd-min-ht">
                                        {/* <Card className="crd-min-ht"> */}
                                        <CardBody>
                                            <div className="">
                                                {/* <a href="#" className="previous round">&#8249;</a> */}

                                                <Row>
                                                    <Col lg="2">
                                                        <div className="mb-3">
                                                            <Label>Exam Type</Label>
                                                            <Select
                                                                value={selectedExamType}
                                                                isDisabled={true}
                                                                // onChange={(selectedOption) => {
                                                                //     validation.setFieldValue("selectedExamType", selectedOption);
                                                                //     handleSelectedExamType(selectedOption);
                                                                // }}
                                                                options={optionExamType.map((option) => ({
                                                                    label: option.typename,
                                                                    value: option.typeid,
                                                                }))}
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                className={`select2-selection ${validation.touched.selectedExamType && validation.errors.selectedExamType ? 'is-invalid' : ''}`}
                                                            />
                                                            {validation.touched.selectedExamType && validation.errors.selectedExamType ? (
                                                                <div className="invalid-feedback">{validation.errors.selectedExamType}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    <Col lg="2">
                                                        <div className="mb-3">
                                                            <Label>Exam Paper<span style={{ color: 'red' }}>*</span></Label>
                                                            <Select
                                                                value={selectedExamPaper}
                                                                onChange={(selectedOption) => {
                                                                    validation.setFieldValue("selectedExamPaper", selectedOption);
                                                                    handleSelectPaper(selectedOption);
                                                                }}
                                                                options={optionExamPaper.map((option) => ({
                                                                    label: option.exp_name,
                                                                    value: option.expid,
                                                                }))}
                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                className={`select2-selection ${validation.touched.selectedExamPaper && validation.errors.selectedExamPaper ? 'is-invalid' : ''}`}
                                                                isDisabled={qid > 0 && isClone === '0' || isClone === '3'}
                                                            />
                                                            {validation.touched.selectedExamPaper && validation.errors.selectedExamPaper ? (
                                                                <div className="invalid-feedback">{validation.errors.selectedExamPaper}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>
                                                    {/* <Col lg="3">
                                                <div className="mb-3">
                                                    <Label>Language<span style={{ color: 'red' }}>*</span></Label>
                                                    <Select
                                                        value={selectedLanguage}
                                                        onChange={(selectedOption) => {
                                                            validation.setFieldValue("selectedLanguage", selectedOption);
                                                            handleSelectLanguage(selectedOption, selectedLanguage?.value);
                                                        }}
                                                        options={optionMasterLanguageData.map((option) => ({
                                                            label: option.l_name,
                                                            value: option.lid,
                                                        }))}

                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        className={`select2-selection ${validation.touched.selectedExamPaper && validation.errors.selectedExamPaper ? 'is-invalid' : ''}`}
                                                    />
                                                    {validation.touched.selectedExamPaper && validation.errors.selectedExamPaper ? (
                                                        <div className="invalid-feedback">{validation.errors.selectedExamPaper}</div>
                                                    ) : null}
                                                </div>
                                            </Col> */}
                                                    <Col lg="2">
                                                        <div className="mb-3">
                                                            <Label>Subject<span style={{ color: 'red' }}>*</span></Label>
                                                            <Select
                                                                value={selectedSubjectName}
                                                                onChange={(selectedOption) => {

                                                                  
                                                                    handleSelectSubjectName(selectedOption);
                                                                    validation.setFieldValue("selectedSubjectName", selectedOption);
                                                                }}
                                                                options={optionSubjectName.map((option) => ({
                                                                    label: option.subjectname,
                                                                    value: option.subjectid,
                                                                }))}

                                                                styles={customStyles}
                                                                validate={{
                                                                    required: { value: true },
                                                                }}

                                                                className={`select2-selection ${validation.touched.selectedSubjectName && validation.errors.selectedSubjectName ? 'is-invalid' : ''}`}
                                                            //isDisabled={isClone === '0'}
                                                            // isDisabled={qid > 0 && isClone === '0'}
                                                            />
                                                            {validation.touched.selectedSubjectName && validation.errors.selectedSubjectName ? (
                                                                <div className="invalid-feedback">{validation.errors.selectedSubjectName}</div>
                                                            ) : null}

                                                        </div>
                                                    </Col>
                                                    <Col lg="3">
                                                        <div className="mb-3">
                                                            <Label>Subcategory
                                                            </Label>
                                                            <Select
                                                                value={selectedSubjectSubcatName}
                                                                onChange={(selectedOption) => {

                                                                    validation.setFieldValue("selectedSubjectName", selectedOption);
                                                                    handleSelectSubjectSubcatName(selectedOption);
                                                                }}
                                                                options={optionSubjectSubcatName.map((option) => ({
                                                                    label: option.ssc_name,
                                                                    value: option.sscid,
                                                                }))}
                                                                // qid > 0 && isClone === '0'
                                                                isDisabled={disabledsubcat}
                                                                // isDisabled={qid == 0 && isClone === '0' ? disabledsubcat : disabledsubcat}

                                                                styles={customStyles}
                                                                validate={{
                                                                    required: { value: true },
                                                                }}

                                                                className={`select2-selection ${validation.touched.selectedSubjectSubcatName && validation.errors.selectedSubjectSubcatName ? 'is-invalid' : ''}`}
                                                            //isDisabled={isClone === '0'}
                                                            // isDisabled={qid > 0 && isClone === '0'}
                                                            />
                                                            {/* {validation.touched.selectedSubjectSubcatName && validation.errors.selectedSubjectSubcatName ? (
                                                        <div className="invalid-feedback">{validation.errors.selectedSubjectSubcatName}</div>
                                                    ) : null} */}

                                                        </div>
                                                    </Col>
                                                    <Col lg="3">
                                                        <div className="mb-3">
                                                            <Label>Topic
                                                            </Label>
                                                            <Select
                                                                value={selectedTopicName}
                                                                onChange={(selectedOption) => {
                                                                    validation.setFieldValue("selectedTopicName", selectedOption);
                                                                    handleSelectTopicName(selectedOption);
                                                                }}
                                                                options={optionTopicName.map((option) => ({
                                                                    label: option.topicname,
                                                                    value: option.topicid,
                                                                }))}
                                                                validate={{
                                                                    required: { value: true },
                                                                }}

                                                                className={`select2-selection ${validation.touched.selectedTopicName && validation.errors.selectedTopicName ? 'is-invalid' : ''}`}
                                                                //isDisabled={isClone === '0'}
                                                                // isDisabled={qid > 0 && isClone === '0'}
                                                                isDisabled={disabledtopic}
                                                            // isDisabled={qid == 0 && isClone === '0' ? disabledtopic : disabledtopic}

                                                            />
                                                            {validation.touched.selectedTopicName && validation.errors.selectedTopicName ? (
                                                                <div className="invalid-feedback">{validation.errors.selectedTopicName}</div>
                                                            ) : null}

                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    {/* <Col lg="3">
                                                <div className="mb-3">
                                                    <Label>Subcategory<span style={{ color: 'red' }}>*</span></Label>
                                                    <Select
                                                        value={selectedSubjectSubcatName}
                                                        onChange={(selectedOption) => {

                                                            validation.setFieldValue("selectedSubjectName", selectedOption);
                                                            handleSelectSubjectSubcatName(selectedOption);
                                                        }}
                                                        options={optionSubjectSubcatName.map((option) => ({
                                                            label: option.ssc_name,
                                                            value: option.sscid,
                                                        }))}

                                                        styles={customStyles}
                                                        validate={{
                                                            required: { value: true },
                                                        }}

                                                        className={`select2-selection ${validation.touched.selectedSubjectSubcatName && validation.errors.selectedSubjectSubcatName ? 'is-invalid' : ''}`}
                                                        //isDisabled={isClone === '0'}
                                                        isDisabled={qid > 0 && isClone === '0'}
                                                    />
                                                    {validation.touched.selectedSubjectSubcatName && validation.errors.selectedSubjectSubcatName ? (
                                                        <div className="invalid-feedback">{validation.errors.selectedSubjectSubcatName}</div>
                                                    ) : null}

                                                </div>
                                            </Col> */}
                                                    {/* <Col lg="3">
                                                <div className="mb-3">
                                                    <Label>Topic<span style={{ color: 'red' }}>*</span></Label>
                                                    <Select
                                                        value={selectedTopicName}
                                                        onChange={(selectedOption) => {
                                                            validation.setFieldValue("selectedTopicName", selectedOption);
                                                            handleSelectTopicName(selectedOption);
                                                        }}
                                                        options={optionTopicName.map((option) => ({
                                                            label: option.topicname,
                                                            value: option.topicid,
                                                        }))}
                                                        validate={{
                                                            required: { value: true },
                                                        }}

                                                        className={`select2-selection ${validation.touched.selectedTopicName && validation.errors.selectedTopicName ? 'is-invalid' : ''}`}
                                                        //isDisabled={isClone === '0'}
                                                        isDisabled={qid > 0 && isClone === '0'}
                                                    />
                                                    {validation.touched.selectedTopicName && validation.errors.selectedTopicName ? (
                                                        <div className="invalid-feedback">{validation.errors.selectedTopicName}</div>
                                                    ) : null}

                                                </div>
                                            </Col> */}
                                                    <Col lg="3">
                                                        <div className="mb-3">
                                                            <Label>Exam Level</Label>
                                                            <Select
                                                                value={selectedExamLevel}
                                                                onChange={(selectedOption) => {
                                                                    validation.setFieldValue("selectedExamLevel", selectedOption);
                                                                    handleSelectedExamLevel(selectedOption);
                                                                }}
                                                                options={optionExamLevel.map((option) => ({
                                                                    label: option.levelname,
                                                                    value: option.levelid,
                                                                }))}
                                                                validate={{
                                                                    required: { value: true },
                                                                }}

                                                                className={`select2-selection ${validation.touched.selectedExamLevel && validation.errors.selectedExamLevel ? 'is-invalid' : ''}`}
                                                            />
                                                            {validation.touched.selectedExamLevel && validation.errors.selectedExamLevel ? (
                                                                <div className="invalid-feedback">{validation.errors.selectedExamLevel}</div>
                                                            ) : null}
                                                        </div>
                                                    </Col>

                                                    <Col lg="2">

                                                        <div className="mb-3">
                                                            <Label>Shuffle Options</Label>
                                                            <div className="square-switch">
                                                                <input
                                                                    type="checkbox"
                                                                    id="shuffle"
                                                                    className="switch switch-info"
                                                                    checked={shuffle}
                                                                    onClick={() => setShuffle(!shuffle)}
                                                                />
                                                                <label htmlFor="shuffle" data-on-label="Yes" data-off-label="No" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg="2">
                                                        {qid > 0 ? (
                                                            <div className="mb-3">
                                                                <Label>Status</Label>
                                                                <div className="square-switch" key={'status'}>

                                                                    <input
                                                                        type="checkbox"
                                                                        id="statusQues"
                                                                        className="switch switch-bool"
                                                                        checked={statusQues}
                                                                        onClick={() =>
                                                                            setstatusQues(!statusQues)
                                                                        }
                                                                    />
                                                                    <label
                                                                        htmlFor="statusQues"
                                                                        data-on-label="Active"
                                                                        data-off-label="Inactive"
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </Col>
                                                    {reason && Number(localStorage.getItem('isClone')) !== 3 ?
                                                        <Col lg="5">
                                                            <Label>Rejected Reason</Label>

                                                            <textarea
                                                                className="form-control"
                                                                id="commentmessage-input"
                                                                placeholder="Type here..."
                                                                rows="3"
                                                                value={reason}
                                                                disabled
                                                            ></textarea>
                                                            {/* <Card className="mb-3 card-custom">
                                                        <CardBody>
                                                            <CardHeader className="text-danger card-custom-title">Rejected Reason</CardHeader>
                                                            <CardText className="card-custom-text" style={{ marginTop: '1%' }}>
                                                                {reason || 'No reason provided'}
                                                            </CardText>
                                                        </CardBody>
                                                    </Card> */}
                                                        </Col> :
                                                        rejectValidation && rejectValidation?.initialValues && rejectValidation?.initialValues?.reason !== "" ?
                                                            <Col lg="5">
                                                                <Label>Rejected Reason</Label>

                                                                <textarea
                                                                    className="form-control"
                                                                    id="commentmessage-input"
                                                                    placeholder="Type here..."
                                                                    rows="3"
                                                                    value={rejectValidation?.initialValues?.reason}
                                                                    disabled
                                                                ></textarea>
                                                                {/* <Card className="mb-3 card-custom">
                                                  <CardBody className="p-2" style={{ border: '1px solid #ddd', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', background: '#f9f9f9' }}>
                                                      <p className="text-danger p-2" style={{ fontSize: '14px', fontWeight: 'bold', borderBottom: '2px solid #ddd' }}>
                                                          Rejected Reason
                                                      </p>
                                                      <CardText className="card-custom-text px-2" style={{ marginTop: '1%', fontSize: '1rem', color: '#333' }}>
                                                          {reason || 'No reason provided'}
                                                      </CardText>
                                                  </CardBody>
                                              </Card> */}

                                                                {/* <Card className="mb-3 card-custom">
                                                  <CardBody>
                                                      <CardHeader className="text-danger card-custom-title">Rejected Reason</CardHeader>
                                                      <CardText className="card-custom-text" style={{ marginTop: '1%' }}>
                                                          {reason || 'No reason provided'}
                                                      </CardText>
                                                  </CardBody>
                                              </Card> */}

                                                                {/* <span className="text-danger">Rejected Reason</span>
                                              <p style={{ marginTop: '1%' }}>{reason}</p> */}
                                                            </Col> : ""

                                                        // <Col lg="5">
                                                        //     <span className="text-danger">Rejected Reason</span>
                                                        //     <p style={{ marginTop: '1%' }}>{reason}</p>
                                                        // </Col>
                                                    }
                                                </Row>





                                                {/* <Col lg="3"> */}
                                                {/* <Row> */}
                                                {/* <Row lg="12">
                                                        <div className="mb-3">
                                                            <Label>Exam Type</Label>
                                                            <Select
                                                                value={selectedExamType}
                                                                isDisabled={true}
                                                                // onChange={(selectedOption) => {
                                                                //     validation.setFieldValue("selectedExamType", selectedOption);
                                                                //     handleSelectedExamType(selectedOption);
                                                                // }}
                                                                options={optionExamType.map((option) => ({
                                                                    label: option.typename,
                                                                    value: option.typeid,
                                                                }))}
                                                                validate={{
                                                                    required: { value: true },
                                                                }}


                                                                className={`select2-selection ${validation.touched.selectedExamType && validation.errors.selectedExamType ? 'is-invalid' : ''}`}

                                                            />
                                                            {validation.touched.selectedExamType && validation.errors.selectedExamType ? (
                                                                <div className="invalid-feedback">{validation.errors.selectedExamType}</div>
                                                            ) : null}
                                                        </div>
                                                    </Row> */}
                                                {/* <Row lg="12">
                                                        <div className="mb-3">
                                                            <Label>Exam Paper<span style={{ color: 'red' }}>*</span></Label>
                                                            <Select
                                                                value={selectedExamPaper}

                                                                onChange={(selectedOption) => {
                                                                    validation.setFieldValue("selectedExamPaper", selectedOption);
                                                                    handleSelectPaper(selectedOption);
                                                                }}
                                                                options={optionExamPaper.map((option) => ({
                                                                    label: option.exp_name,
                                                                    value: option.expid,
                                                                }))}

                                                                validate={{
                                                                    required: { value: true },
                                                                }}

                                                                className={`select2-selection ${validation.touched.selectedExamPaper && validation.errors.selectedExamPaper ? 'is-invalid' : ''}`}
                                                                isDisabled={qid > 0 && isClone === '0'}
                                                            />
                                                            {validation.touched.selectedExamPaper && validation.errors.selectedExamPaper ? (
                                                                <div className="invalid-feedback">{validation.errors.selectedExamPaper}</div>
                                                            ) : null}

                                                        </div>
                                                    </Row> */}
                                                {/* <Row lg="12">
                                                        <div className="mb-3">
                                                            <Label>Language<span style={{ color: 'red' }}>*</span></Label>
                                                            <Select
                                                                value={selectedLanguage}
                                                                onChange={(selectedOption) => {
                                                                    validation.setFieldValue("selectedLanguage", selectedOption);
                                                                    handleSelectLanguage(selectedOption, selectedLanguage?.value);
                                                                }}
                                                                options={optionMasterLanguageData.map((option) => ({
                                                                    label: option.l_name,
                                                                    value: option.lid,
                                                                }))}

                                                                validate={{
                                                                    required: { value: true },
                                                                }}
                                                                className={`select2-selection ${validation.touched.selectedExamPaper && validation.errors.selectedExamPaper ? 'is-invalid' : ''}`}
                                                            />
                                                            {validation.touched.selectedExamPaper && validation.errors.selectedExamPaper ? (
                                                                <div className="invalid-feedback">{validation.errors.selectedExamPaper}</div>
                                                            ) : null}
                                                        </div>
                                                    </Row> */}
                                                {/* <Row lg="12">
                                                        <div className="mb-3">
                                                            <Label>Subject<span style={{ color: 'red' }}>*</span></Label>
                                                            <Select
                                                                value={selectedSubjectName}
                                                                onChange={(selectedOption) => {

                                                                    validation.setFieldValue("selectedSubjectName", selectedOption);
                                                                    handleSelectSubjectName(selectedOption);
                                                                }}
                                                                options={optionSubjectName.map((option) => ({
                                                                    label: option.subjectname,
                                                                    value: option.subjectid,
                                                                }))}

                                                                styles={customStyles}
                                                                validate={{
                                                                    required: { value: true },
                                                                }}

                                                                className={`select2-selection ${validation.touched.selectedSubjectName && validation.errors.selectedSubjectName ? 'is-invalid' : ''}`}
                                                                //isDisabled={isClone === '0'}
                                                                isDisabled={qid > 0 && isClone === '0'}
                                                            />
                                                            {validation.touched.selectedSubjectName && validation.errors.selectedSubjectName ? (
                                                                <div className="invalid-feedback">{validation.errors.selectedSubjectName}</div>
                                                            ) : null}

                                                        </div>
                                                    </Row> */}
                                                {/* <Row lg="12">
                                                        <div className="mb-3">
                                                            <Label>Topic<span style={{ color: 'red' }}>*</span></Label>
                                                            <Select
                                                                value={selectedTopicName}
                                                                onChange={(selectedOption) => {
                                                                    validation.setFieldValue("selectedTopicName", selectedOption);
                                                                    handleSelectTopicName(selectedOption);
                                                                }}
                                                                options={optionTopicName.map((option) => ({
                                                                    label: option.topicname,
                                                                    value: option.topicid,
                                                                }))}
                                                                validate={{
                                                                    required: { value: true },
                                                                }}

                                                                className={`select2-selection ${validation.touched.selectedTopicName && validation.errors.selectedTopicName ? 'is-invalid' : ''}`}
                                                                //isDisabled={isClone === '0'}
                                                                isDisabled={qid > 0 && isClone === '0'}
                                                            />
                                                            {validation.touched.selectedTopicName && validation.errors.selectedTopicName ? (
                                                                <div className="invalid-feedback">{validation.errors.selectedTopicName}</div>
                                                            ) : null}

                                                        </div>
                                                    </Row> */}

                                                {/* </Row> */}
                                                {/* <Row lg="12">
                                                <Col lg="6">

                                                    <div className="mb-3">
                                                        <Label>Shuffle Options</Label>
                                                        <div className="square-switch">
                                                            <input
                                                                type="checkbox"
                                                                id="shuffle"
                                                                className="switch switch-info"
                                                                checked={shuffle}
                                                                onClick={() => setShuffle(!shuffle)}
                                                            />
                                                            <label htmlFor="shuffle" data-on-label="Yes" data-off-label="No" />
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col lg="6">
                                                    {qid > 0 ? (
                                                        <div className="mb-3">
                                                            <Label>Status</Label>
                                                            <div className="square-switch" key={'status'}>

                                                                <input
                                                                    type="checkbox"
                                                                    id="statusQues"
                                                                    className="switch switch-bool"
                                                                    checked={statusQues}
                                                                    onClick={() =>
                                                                        setstatusQues(!statusQues)
                                                                    }
                                                                />
                                                                <label
                                                                    htmlFor="statusQues"
                                                                    data-on-label="Active"
                                                                    data-off-label="Inactive"
                                                                />
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </Col>
                                            </Row> */}

                                                {/* </Col> */}
                                                <Row>
                                                    <Nav tabs className="nav-tabs-custom nav-justified">
                                                        {langArr?.includes("1") ?
                                                            <NavItem>
                                                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: languageActiveTab === "1", })} onClick={() => { changeLangugeTab("1"); }}>
                                                                    <span className="d-block d-sm-none">
                                                                        <i className="fas fa-home"></i>
                                                                    </span>
                                                                    <span className="d-none d-sm-block">English</span>
                                                                </NavLink>
                                                            </NavItem>
                                                            : null}
                                                        {langArr?.includes("2") ?

                                                            <NavItem>
                                                                <NavLink style={{ cursor: "pointer" }} className={classnames({ active: languageActiveTab === "2", })} onClick={() => { changeLangugeTab("2"); }}>
                                                                    <span className="d-block d-sm-none">
                                                                        <i className="far fa-user"></i>
                                                                    </span>
                                                                    <span className="d-none d-sm-block">Regional</span>
                                                                </NavLink>
                                                            </NavItem>
                                                            : null}
                                                        <TabContent
                                                            activeTab={languageActiveTab}
                                                            className="p-3 text-muted"
                                                        >
                                                            <TabPane         style={{
          pointerEvents: (approveStatusArray.length > 0 && (approveStatusArray[0] == 4 ) && user?.ur_default != 1) ? "none" : "auto",
          opacity: (approveStatusArray.length > 0 && (approveStatusArray[0] == 4 && user?.ur_default != 1)) ? 0.5 : 1,
        }}
 tabId="1">
                                                                <div className="card-body">
                                                                    <Row>
                                                                        {engReason != "" && engReason != null && engReason != undefined?
                                                                    <Card className="mb-3 card-custom">
                                                        <CardBody>
                                                            <CardHeader className="text-danger card-custom-title">Rejected Reason</CardHeader>
                                                            <CardText className="card-custom-text" style={{ marginTop: '1%' }}>
                                                                {engReason || 'No reason provided'}
                                                            </CardText>
                                                        </CardBody>
                                                    </Card>:null}

                                                                        {reason && Number(localStorage.getItem('isClone')) !== 3 ?
                                                                            <Col lg="10">
                                                                                <div className="mb-3">

                                                                                    <Label>Rejected Reason</Label>

                                                                                    <textarea
                                                                                        className="form-control"
                                                                                        id="commentmessage-input"
                                                                                        placeholder="Type here..."
                                                                                        rows="3"
                                                                                        value={reason}
                                                                                        disabled
                                                                                    ></textarea>
                                                                                </div>
                                                                                {/* <Card className="mb-3 card-custom">
                                                        <CardBody>
                                                            <CardHeader className="text-danger card-custom-title">Rejected Reason</CardHeader>
                                                            <CardText className="card-custom-text" style={{ marginTop: '1%' }}>
                                                                {reason || 'No reason provided'}
                                                            </CardText>
                                                        </CardBody>
                                                    </Card> */}
                                                                            </Col> :
                                                                            rejectValidation && rejectValidation?.initialValues && rejectValidation?.initialValues?.reason !== "" ?
                                                                                <Col lg="5">
                                                                                    <Label>Rejected Reason</Label>

                                                                                    <textarea
                                                                                        className="form-control"
                                                                                        id="commentmessage-input"
                                                                                        placeholder="Type here..."
                                                                                        rows="3"
                                                                                        value={rejectValidation?.initialValues?.reason}
                                                                                        disabled
                                                                                    ></textarea>
                                                                                    {/* <Card className="mb-3 card-custom">
                                                  <CardBody className="p-2" style={{ border: '1px solid #ddd', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', background: '#f9f9f9' }}>
                                                      <p className="text-danger p-2" style={{ fontSize: '14px', fontWeight: 'bold', borderBottom: '2px solid #ddd' }}>
                                                          Rejected Reason
                                                      </p>
                                                      <CardText className="card-custom-text px-2" style={{ marginTop: '1%', fontSize: '1rem', color: '#333' }}>
                                                          {reason || 'No reason provided'}
                                                      </CardText>
                                                  </CardBody>
                                              </Card> */}

                                                                                    {/* <Card className="mb-3 card-custom">
                                                  <CardBody>
                                                      <CardHeader className="text-danger card-custom-title">Rejected Reason</CardHeader>
                                                      <CardText className="card-custom-text" style={{ marginTop: '1%' }}>
                                                          {reason || 'No reason provided'}
                                                      </CardText>
                                                  </CardBody>
                                              </Card> */}

                                                                                    {/* <span className="text-danger">Rejected Reason</span>
                                              <p style={{ marginTop: '1%' }}>{reason}</p> */}
                                                                                </Col> : ""


                                                                        }
                                                                        <Col lg={10}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="commentmessage-input">
                                                                                    Question
                                                                                </Label>
                                                                                {checkedEngQnEditorEble == true ?

                        <div ref={engQnIframeRef} id="engqneditor" style={{ minHeight: '300px' ,top:900}}></div>

                                                                                    // <iframe
                                                                                    //     ref={engQnIframeRef}
                                                                                    //     title="Embedded HTML"
                                                                                    //     src={htmlContent}
                                                                                    //     width="100%"
                                                                                    //     height="400px"
                                                                                    // /> 
                                                                                    :


                                                                                    <textarea
                                                                                        disabled={regionalBasedDisable === 1}

                                                                                        className="form-control"
                                                                                        id="commentmessage-input"
                                                                                        placeholder="Type here..."
                                                                                        rows="3"
                                                                                        value={engQnTxt}
                                                                                        onChange={(e) => {
                                                                                            let value = e.target.value.replace(constants.regexValidation.removeOtherLanguage, "");
                                                                                            setEngQnTxt(value)
                                                                                        }
                                                                                        }

                                                                                    ></textarea>

                                                                                }  </div>

                                                                        </Col>
                                                                        <Col lg={2}>
                                                                            <div className="square-switch">

                                                                                <input
                                                                                    disabled={regionalBasedDisable === 1}

                                                                                    type="checkbox"
                                                                                    id="engQnEditorEble"
                                                                                    className="switch switch-info"
                                                                                    checked={checkedEngQnEditorEble || false}

                                                                                    onClick={() => {
                                                                                        if (checkedEngQnEditorEble === true) {
                                                                                            if (editorEngQnTxt) {
                                                                                                showConfirmationtoggle('english', 'editor')
                                                                                                return
                                                                                            }
                                                                                        } else {
                                                                                            if (engQnTxt && engQnTxt.length > 0) {
                                                                                                showConfirmationtoggle('english', 'textarea')
                                                                                                return
                                                                                            }
                                                                                        }
                                                                                        setCheckedEngQnEditorEble(!checkedEngQnEditorEble)
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    htmlFor="engQnEditorEble"
                                                                                    data-on-label="Editor"
                                                                                    data-off-label="Input"
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                        {qnError && qnError == true ? (
                                                                            <div style={{ display: 'block' }} className="invalid-feedback">{"Please Enter the Question"}</div>
                                                                        ) : null}
                                                                    </Row>


                                                                </div>

                                                                <Row style={{ marginTop: "10px", marginLeft: "14px" }}>
                                                                    <Label>Options</Label>


                                                                    {engOptionValues.map((value, index) => (
                                                                        <Row className="mb-3" key={index}>
                                                                            {index !== 4 ? (
                                                                                <Col style={{ width: '10px' }} className="col-1">
                                                                                    <div className="form-check form-radio-success mb-3">
                                                                                        <input
                                                                                            disabled={regionalBasedDisable === 1}

                                                                                            type="radio"
                                                                                            id={index + "isAns"}
                                                                                            name={index + "isAns"}
                                                                                            className="form-check-input"
                                                                                            checked={checkedAnswerToggle[index] || false}
                                                                                            onClick={() => handleCheckboxAnsValue(index)}
                                                                                        />
                                                                                    </div>
                                                                                </Col>
                                                                            ) : (
                                                                                <Col style={{ width: '10px' }} className="col-1">
                                                                                    <div style={{ marginRight: '20px' }}></div> {/* Spacer for index 4 */}
                                                                                </Col>
                                                                            )}

                                                                            <Col className="col-9">
                                                                                {checkedInputToggle[index] === true ? (

<div ref={engOptionsRef.current[index]} id="engnoteseditor" style={{ minHeight: '300px' ,top:900}}></div>

                                                                                    // <iframe disabled={regionalBasedDisable === 1}

                                                                                    //     ref={engOptionsRef.current[index]}
                                                                                    //     title={engOptionsRef.current[index]}
                                                                                    //     src={htmlContent}
                                                                                    //     width="100%"
                                                                                    //     height="300"
                                                                                    // />
                                                                                ) : (
                                                                                    <Input
                                                                                        disabled={regionalBasedDisable === 1}

                                                                                        type="text"
                                                                                        value={value}
                                                                                        onChange={(e) => handleEngOptionInputChange(index, e.target.value)}
                                                                                    />
                                                                                )}
                                                                            </Col>
                                                                            <Col className="col-1">
                                                                                <div className="square-switch">
                                                                                    <input
                                                                                        disabled={regionalBasedDisable === 1}

                                                                                        type="checkbox"
                                                                                        id={index + "enableEditor"}
                                                                                        className="switch switch-info"
                                                                                        checked={checkedInputToggle[index] || false}
                                                                                        onClick={() => {
                                                                                            // if (checkedInputToggle[index] === true) {
                                                                                            //     if (engOptionsRef.current[index]?.current?.contentWindow?.updatedContent) {
                                                                                            //         showConfirmationtoggle('english_options', 'editor', index);
                                                                                            //         return;
                                                                                            //     }
                                                                                            // } else {
                                                                                            //     if (value && value.length > 0) {
                                                                                            //         showConfirmationtoggle('english_options', 'textarea', index);
                                                                                            //         return;
                                                                                            //     }
                                                                                            // }
                                                                                            handleCheckboxInputValue(index);
                                                                                        }}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor={index + "enableEditor"}
                                                                                        data-on-label="Editor"
                                                                                        data-off-label="Input"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            {showEngDeleteIcon && (
                                                                                <Col className="col-1">
                                                                                    <Link
                                                                                        to="#"
                                                                                        className="btn btn-sm btn-soft-danger"
                                                                                        onClick={() => {
                                                                                            onClickDelete(index, "1");
                                                                                        }}
                                                                                    >
                                                                                        <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                                                                        <UncontrolledTooltip placement="top" target="deletetooltip">
                                                                                            Delete
                                                                                        </UncontrolledTooltip>
                                                                                    </Link>
                                                                                </Col>
                                                                            )}
                                                                        </Row>
                                                                    ))}



                                                                </Row>
                                                                <Row className="textar">
                                                                    <Col lg={10}>


                                                                        <Label>Notes</Label>

                                                                        <div className="mb-3">
                                                                            {/* <Label htmlFor="regqn-input">
                                                                                Question
                                                                            </Label> */}
                                                                            {checkedEngNotesEditorEble == true ?

                                                                                // <iframe disabled={regionalBasedDisable === 1}


                                                                                //     ref={enNotesIframeRef}
                                                                                //     title="Embedded HTML"
                                                                                //     src={htmlContent}
                                                                                //     width="100%"
                                                                                //     height="400px"
                                                                                // /> 
                                                                                <div ref={enNotesIframeRef} id="engnoteseditor" style={{ minHeight: '300px' ,top:900}}></div>

                                                                                :


                                                                                <textarea
                                                                                    className="form-control"
                                                                                    id="commentmessage-input"
                                                                                    placeholder="Type here..."
                                                                                    rows="3"
                                                                                    value={notes}
                                                                                    disabled={regionalBasedDisable === 1}

                                                                                    onChange={(e) => {
                                                                                        let value = e.target.value.replace(constants.regexValidation.removeOtherLanguage, "");
                                                                                        setNotes(value)
                                                                                    }
                                                                                    }

                                                                                ></textarea>
                                                                            }  </div>
                                                                    </Col>
                                                                    <Col lg={2}>

                                                                        <div className="square-switch">


                                                                            <input
                                                                                type="checkbox"
                                                                                id="enNotesEditorEble"
                                                                                className="switch switch-info"
                                                                                disabled={regionalBasedDisable === 1}

                                                                                // checked={checkedRegQnEditorEble || false}
                                                                                checked={checkedEngNotesEditorEble}
                                                                                onClick={() => {
                                                                                    if (checkedEngNotesEditorEble === true) {
                                                                                        if (editorEngNotesTxt) {
                                                                                            showConfirmationtoggle('english', 'editor')
                                                                                            return
                                                                                        }
                                                                                    } else {
                                                                                        if (regNotesTxt && regNotesTxt.length > 0) {
                                                                                            showConfirmationtoggle('english', 'textarea')
                                                                                            return
                                                                                        }
                                                                                    }
                                                                                    setCheckedEngNotesEditorEble(!checkedEngNotesEditorEble)
                                                                                }

                                                                                }
                                                                            />
                                                                            <label
                                                                                htmlFor="enNotesEditorEble"
                                                                                data-on-label="Editor"
                                                                                data-off-label="Input"
                                                                            />
                                                                        </div>
                                                                    </Col>






                                                                    {/* <textarea
                                                                    className="form-control"
                                                                    id="commentmessage-input"
                                                                    placeholder="Type here..."
                                                                    rows="3"
                                                                    value={notes}
                                                                    onChange={(e) => {
                                                                        let value = e.target.value.replace(constants.regexValidation.removeOtherLanguage, "");
                                                                        setNotes(value)
                                                                    }
                                                                    }

                                                                ></textarea> */}
                                                                </Row>
                                                                <div className="d-flex justify-content-end align-items-center">
                                                                    {(approveStatusArray.length > 0 && (approveStatusArray[0] == 3 ) && isClone == 3) && userLangArray.includes('1') &&
                                                                        user.user_previleges.some(p =>
                                                                            Number(p.urp_smid) === constants.SubmenuList.QAApproval &&
                                                                            p.urp_type === constants.menuTypes.Permission1
                                                                        ) && (
                                                                            <>
                                                                              
                                                                                        <Button
                                                                                            color="success"
                                                                                            type="button"
                                                                                            id="save"
                                                                                            className="save-user me-2"
                                                                                            onClick={() =>
                                                                                                {
                                                                                                    setApproveLang(1)
                                                                                                    setApproveModal(true)}
                                                                                                }

                                                                                        >
                                                                                            Approve
                                                                                        </Button>
                                                                                        <Button
                                                                                            color="warning"
                                                                                            type="button"
                                                                                            id="save"
                                                                                            className="save-user me-2"
                                                                                            onClick={() => {
                                                                                                setRejectModal(true);
                                                                                                setRejectLang(1)
                                                                                                if (rejectModal) inputRef.current.focus();
                                                                                            }}
                                                                                        >
                                                                                            Reject
                                                                                        </Button>
                                                                                
                                                                            </>
                                                                        )}

                                                                    {/* {qid === 0 || (qid !== 0 && englishBasedTab === 1) && ( */}

                                                                    {
                                                                 ((approveStatusArray.length == 0)  ||   (approveStatusArray.length > 0 && (approveStatusArray[0] != 3 && approveStatusArray[0] != 4))) && isClone != 3
                                                                       
                                                                            ?

                                                                            // handleSubmitApprove(1)
                                                                            <button type="button" onClick={() => 
                                                                                setSubmitConfirmModal(true)
                                                                           } className="btn btn-primary ms-2">
                                                                                Submit for approval
                                                                            </button> : ''
                                                                    }
                                                                    {/* )} */}
                                                                </div>




                                                            </TabPane>
                                                            <TabPane style={{
          pointerEvents: (((approveStatusArray.length ==1 && approveStatusArray[0] == 4 ) || (approveStatusArray.length > 1 && approveStatusArray[1] == 4 )) && user?.ur_default != 1) ? "none" : "auto",
          opacity: (((approveStatusArray.length ==1 && approveStatusArray[0] == 4 ) || (approveStatusArray.length > 1 && approveStatusArray[1] == 4 )) && user?.ur_default != 1) ? 0.5 : 1,
        }} tabId="2">
                                                                <div className="card-body">
                                                                    <Row>
                                                                    {regReason != "" && regReason != null && regReason != undefined?
                                                                    <Card className="mb-3 card-custom">
                                                        <CardBody>
                                                            <CardHeader className="text-danger card-custom-title">Rejected Reason</CardHeader>
                                                            <CardText className="card-custom-text" style={{ marginTop: '1%' }}>
                                                                {regReason || 'No reason provided'}
                                                            </CardText>
                                                        </CardBody>
                                                    </Card>:null}
                                                                        {reason && Number(localStorage.getItem('isClone')) !== 3 ?
                                                                            <Col lg="10">
                                                                                <div className="mb-3">

                                                                                    <Label>Rejected Reason</Label>

                                                                                    <textarea
                                                                                        className="form-control"
                                                                                        id="commentmessage-input"
                                                                                        placeholder="Type here..."
                                                                                        rows="3"
                                                                                        value={reason}
                                                                                        disabled
                                                                                    ></textarea>
                                                                                </div>
                                                                                {/* <Card className="mb-3 card-custom">
                                                        <CardBody>
                                                            <CardHeader className="text-danger card-custom-title">Rejected Reason</CardHeader>
                                                            <CardText className="card-custom-text" style={{ marginTop: '1%' }}>
                                                                {reason || 'No reason provided'}
                                                            </CardText>
                                                        </CardBody>
                                                    </Card> */}
                                                                            </Col> :
                                                                            rejectValidation && rejectValidation?.initialValues && rejectValidation?.initialValues?.reason !== "" ?
                                                                                <Col lg="10">
                                                                                    <Label>Rejected Reason</Label>

                                                                                    <textarea
                                                                                        className="form-control"
                                                                                        id="commentmessage-input"
                                                                                        placeholder="Type here..."
                                                                                        rows="3"
                                                                                        value={rejectValidation?.initialValues?.reason}
                                                                                        disabled
                                                                                    ></textarea>
                                                                                    {/* <Card className="mb-3 card-custom">
                                                  <CardBody className="p-2" style={{ border: '1px solid #ddd', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)', background: '#f9f9f9' }}>
                                                      <p className="text-danger p-2" style={{ fontSize: '14px', fontWeight: 'bold', borderBottom: '2px solid #ddd' }}>
                                                          Rejected Reason
                                                      </p>
                                                      <CardText className="card-custom-text px-2" style={{ marginTop: '1%', fontSize: '1rem', color: '#333' }}>
                                                          {reason || 'No reason provided'}
                                                      </CardText>
                                                  </CardBody>
                                              </Card> */}

                                                                                    {/* <Card className="mb-3 card-custom">
                                                  <CardBody>
                                                      <CardHeader className="text-danger card-custom-title">Rejected Reason</CardHeader>
                                                      <CardText className="card-custom-text" style={{ marginTop: '1%' }}>
                                                          {reason || 'No reason provided'}
                                                      </CardText>
                                                  </CardBody>
                                              </Card> */}

                                                                                    {/* <span className="text-danger">Rejected Reason</span>
                                              <p style={{ marginTop: '1%' }}>{reason}</p> */}
                                                                                </Col> : ""


                                                                        }
                                                                        <Col lg={10}>
                                                                            <div className="mb-3">
                                                                                <Label htmlFor="regqn-input">
                                                                                    Question
                                                                                </Label>
                                                                                {checkedRegQnEditorEble == true ?

<div ref={regQnIframeRef} id="regqneditor" style={{ minHeight: '300px' ,top:900}}></div>

                                                                                    // <iframe
                                                                                    //     disabled={englishBasedDisable === 1}

                                                                                    //     ref={regQnIframeRef}
                                                                                    //     title="Embedded HTML"
                                                                                    //     src={htmlContent}
                                                                                    //     width="100%"
                                                                                    //     height="400px"
                                                                                    // /> 
                                                                                    
                                                                                    :


                                                                                    <textarea
                                                                                        className="form-control"
                                                                                        id="regqn-input"
                                                                                        placeholder="Type here..."
                                                                                        rows="3"
                                                                                        value={regQnTxt}
                                                                                        onChange={(e) =>
                                                                                            setRegQnTxt(e.target.value)}
                                                                                        disabled={englishBasedDisable === 1}
                                                                                    ></textarea>

                                                                                }  </div>

                                                                        </Col>
                                                                        <Col lg={2}>
                                                                            <div className="square-switch">


                                                                                <input
                                                                                   disabled={englishBasedDisable === 1}

                                                                                    type="checkbox"
                                                                                    id="regQnEditorEble"
                                                                                    className="switch switch-info"
                                                                                    // checked={checkedRegQnEditorEble || false}
                                                                                    checked={checkedRegQnEditorEble}
                                                                                    onClick={() => {
                                                                                        if (checkedRegQnEditorEble === true) {
                                                                                            if (regQnIframeRef?.current?.contentWindow?.updatedContent) {
                                                                                                showConfirmationtoggle('regional', 'editor')
                                                                                                return
                                                                                            }
                                                                                        } else {
                                                                                            if (regQnTxt && regQnTxt.length > 0) {
                                                                                                showConfirmationtoggle('regional', 'textarea')
                                                                                                return
                                                                                            }
                                                                                        }
                                                                                        setCheckedRegQnEditorEble(!checkedRegQnEditorEble)
                                                                                    }

                                                                                    }
                                                                                />
                                                                                <label
                                                                                    htmlFor="regQnEditorEble"
                                                                                    data-on-label="Editor"
                                                                                    data-off-label="Input"
                                                                                />
                                                                            </div>
                                                                        </Col>
                                                                    </Row>


                                                                </div>

                                                                <Row style={{ marginTop: "10px", marginLeft: "14px" }}>
                                                                    <Label>Options</Label>

                                                                    {regOptionValues.map((value, index) => (
                                                                        <Row className="mb-3" key={index}>
                                                                            {index !== 4 ? ( // Hide the radio button if it's the fifth option (index 4)
                                                                                <Col style={{ width: '10px' }} className="col-1">
                                                                                    <div className="form-check form-radio-success mb-3">
                                                                                        <input
                                                                                            disabled={englishBasedDisable === 1}

                                                                                            type="radio"
                                                                                            id={index + "regisAns"}
                                                                                            name="regisAns" // Use the same name for grouping
                                                                                            className="form-check-input"
                                                                                            checked={checkedRegAnswerToggle[index] || false}
                                                                                            onClick={() => handleCheckboxRegAnsValue(index)}
                                                                                        />
                                                                                    </div>
                                                                                </Col>) : (
                                                                                <Col style={{ width: '10px' }} className="col-1">
                                                                                    <div style={{ marginRight: '20px' }}></div> {/* Spacer for index 4 */}
                                                                                </Col>

                                                                            )}
                                                                            <Col className="col-9">
                                                                                {checkedRegInputToggle[index] === true ? (

<div ref={regOptionsRef.current[index]} id="regoptioneditor" style={{ minHeight: '300px' ,top:900}}></div>
                                                                                    // <iframe
                                                                                    //     disabled={englishBasedDisable === 1}

                                                                                    //     ref={regOptionsRef.current[index]}
                                                                                    //     title={`Option ${index}`}
                                                                                    //     src={htmlContent} // Ensure htmlContent is defined
                                                                                    //     width="100%"
                                                                                    //     height="300"
                                                                                    // />
                                                                                ) : (
                                                                                    <Input
                                                                                        disabled={englishBasedDisable === 1}

                                                                                        type="text"
                                                                                        value={value}
                                                                                        onChange={(e) => handleRegOptionInputChange(index, e.target.value)}
                                                                                    />
                                                                                )}
                                                                            </Col>
                                                                            <Col className="col-1">
                                                                                <div className="square-switch">
                                                                                    <input
                                                                                        disabled={englishBasedDisable === 1}

                                                                                        type="checkbox"
                                                                                        id={"regenableEditor" + index}
                                                                                        className="switch switch-info"
                                                                                        checked={checkedRegInputToggle[index] || false}
                                                                                        onClick={() => {
                                                                                            // if (checkedRegInputToggle[index] === true) {
                                                                                            //     if (regOptionsRef.current[index]?.current?.contentWindow?.updatedContent) {
                                                                                            //         showConfirmationtoggle('regional_options', 'editor', index);
                                                                                            //         return;
                                                                                            //     }
                                                                                            // } else {
                                                                                            //     if (value && value.length > 0) {
                                                                                            //         showConfirmationtoggle('regional_options', 'textarea', index);
                                                                                            //         return;
                                                                                            //     }
                                                                                            // }
                                                                                            handleCheckboxRegInputValue(index);
                                                                                        }}
                                                                                    />
                                                                                    <label
                                                                                        htmlFor={"regenableEditor" + index}
                                                                                        data-on-label="Editor"
                                                                                        data-off-label="Input"
                                                                                    />
                                                                                </div>
                                                                            </Col>
                                                                            {showRegDeleteIcon && (
                                                                                <Col className="col-1">
                                                                                    <Link
                                                                                        to="#"
                                                                                        className="btn btn-sm btn-soft-danger"
                                                                                        onClick={() => onClickDelete(index, "2")}
                                                                                    >
                                                                                        <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                                                                        <UncontrolledTooltip placement="top" target="deletetooltip">
                                                                                            Delete
                                                                                        </UncontrolledTooltip>
                                                                                    </Link>
                                                                                </Col>
                                                                            )}
                                                                        </Row>
                                                                    ))}



                                                                </Row>


                                                                <Row className="textar">
                                                                    <Col lg={10}>
                                                                        <Label>Notes</Label>

                                                                        <div className="mb-3">
                                                                            {/* <Label htmlFor="regqn-input">
                                                                                Question
                                                                            </Label> */}
                                                                            {checkedRegNotesEditorEble == true ?

                                                                                // <iframe
                                                                                //     disabled={englishBasedDisable === 1 || multiLanguageBasedTab !== 1}

                                                                                //     ref={regNotesIframeRef}
                                                                                //     title="Embedded HTML"
                                                                                //     src={htmlContent}
                                                                                //     width="100%"
                                                                                //     height="400px"
                                                                                // /> 
                                                                                <div ref={regNotesIframeRef} id="regnoteseditor" style={{ minHeight: '300px' ,top:900}}></div>
                                                                                
                                                                                :


                                                                                <textarea
                                                                                    disabled={englishBasedDisable === 1 || multiLanguageBasedTab !== 1}

                                                                                    className="form-control"
                                                                                    id="commentmessage-input"
                                                                                    placeholder="Type here..."
                                                                                    rows="3"
                                                                                    value={regNotesTxt}
                                                                                    onChange={(e) => {
                                                                                        let value = e.target.value
                                                                                        setRegNotesTxt(value)
                                                                                    }
                                                                                    }

                                                                                ></textarea>
                                                                            }  </div>
                                                                    </Col>
                                                                    <Col lg={2}>
                                                                        <div className="square-switch">


                                                                            <input
                                                                                disabled={englishBasedDisable === 1 || multiLanguageBasedTab !== 1}

                                                                                type="checkbox"
                                                                                id="regNotesEditorEble"
                                                                                className="switch switch-info"
                                                                                // checked={checkedRegQnEditorEble || false}
                                                                                checked={checkedRegNotesEditorEble}
                                                                                onClick={() => {
                                                                                
                                                                                    if (checkedRegNotesEditorEble === true) {
                                                                                        if (editorRegNotesTxt) {
                                                                                            showConfirmationtoggle('regional', 'editor')
                                                                                            return
                                                                                        }
                                                                                    } else {
                                                                                        if (regNotesTxt && regNotesTxt.length > 0) {
                                                                                            showConfirmationtoggle('regional', 'textarea')
                                                                                            return
                                                                                        }
                                                                                    }
                                                                                    setCheckedRegNotesEditorEble(!checkedRegNotesEditorEble)
                                                                                }

                                                                                }
                                                                            />
                                                                            <label
                                                                                htmlFor="regNotesEditorEble"
                                                                                data-on-label="Editor"
                                                                                data-off-label="Input"
                                                                            />
                                                                        </div>
                                                                    </Col>



                                                                    {/* <iframe

ref={regQnIframeRef}
title="Embedded HTML"
src={htmlContent}
width="100%"
height="400px"
/> */}
                                                                    {/* <textarea
                                                                    className="form-control"
                                                                    id="commentmessage-input"
                                                                    placeholder="Type here..."
                                                                    rows="3"
                                                                    value={reqnotes}
                                                                    onChange={(e) => {
                                                                        let value = e.target.value
                                                                        setReqNotes(value)
                                                                    }
                                                                    }

                                                                ></textarea> */}




                                                                </Row>
                                                                <div className="d-flex justify-content-end align-items-center">
                                                                {((approveStatusArray.length ==1 && approveStatusArray[0] == 3 ) || (approveStatusArray.length > 1 && approveStatusArray[1] == 3 ))&& isClone == 3 && userLangArray.includes('2') &&
                                                                        user.user_previleges.some(p =>
                                                                            Number(p.urp_smid) === constants.SubmenuList.QAApproval &&
                                                                            p.urp_type === constants.menuTypes.Permission1
                                                                        ) && (
                                                                            <>
                                                                              
                                                                                        <Button
                                                                                            color="success"
                                                                                            type="button"
                                                                                            id="save"
                                                                                            className="save-user me-2"
                                                                                            onClick={() =>
                                                                                                {
                                                                                                    setApproveLang(2)
                                                                                                    setApproveModal(true)}
                                                                                                }
                                                                                        >
                                                                                            Approve
                                                                                        </Button>
                                                                                        <Button
                                                                                            color="warning"
                                                                                            type="button"
                                                                                            id="save"
                                                                                            className="save-user me-2"
                                                                                            onClick={() => {
                                                                                                setRejectModal(true);
                                                                                                setRejectLang(2)
                                                                                                if (rejectModal) inputRef.current.focus();
                                                                                            }}
                                                                                        >
                                                                                            Reject
                                                                                        </Button>
                                                                                
                                                                            </>
                                                                        )}

                                                                    {/* {(qid === 0 || (qid !== 0 && regionalBasedTab === 1)) && ( */}
                                                                    {

((approveStatusArray.length == 0)  ||   (approveStatusArray.length > 0 && (approveStatusArray[1] != 3 && approveStatusArray[1] != 4)) )&& isClone != 3
                                                                   
                                                                      
                                                                            // && qaApprovalSubmitButton == 2)
                                                                            ?

                                                                            <button
                                                                                type="button"
                                                                                onClick={() =>  setRegSubmitConfirmModal(true)}
                                                                                className="btn btn-primary ms-2"
                                                                            >
                                                                                Submit for approval
                                                                            </button> : ""


                                                                    }

                                                                    {/* )} */}
                                                                </div>



                                                            </TabPane>
                                                        </TabContent>
                                                    </Nav>
                                                </Row>
                                            </div>
                                            {/* </Row> */}
                                            {/* {((qid != 0 && approvebutton == true && isClone == "3") || (qid != 0 && rejectbutton == false && isClone == "3")) && ((user.user_previleges.filter(p =>
                                                Number(p.urp_smid) === constants.SubmenuList.QAApproval && (p.urp_type === constants.menuTypes.Permission1)).length === 1)
                                            ) ? <> <div className={`${recordId === qaList[0]?.qid ? 'arrow left-arrow disabled' : 'arrow left-arrow'}`} onClick={Previous}>
                                                &#8249; 
                                            </div >
                                                <div className={`${recordId === qaList[qaList?.length - 1]?.qid ? 'arrow right-arrow disabled' : 'arrow right-arrow'}`} onClick={Next} disabled>
                                                    &#8250; 
                                                </div ></> : ''} */}

                                        </CardBody>
                                        <CardFooter>
                                            <Row className="mb-3">
                                                <div className="d-flex flex-wrap gap-2 justify-content-end">


                                                    {/* {((qid != 0 && approvebutton == true && isClone == "3") || (qid != 0 && rejectbutton == false && isClone == "3")) && ((user.user_previleges.filter(p =>
                                                        Number(p.urp_smid) === constants.SubmenuList.QAApproval && (p.urp_type === constants.menuTypes.Permission1)).length === 1)
                                                    ) ?
                                                        <div>
                                                            <Button color="success"
                                                                type="button"
                                                                id="save"
                                                                className="save-user me-2"
                                                                onClick={() => setApproveModal(true)}
                                                            >
                                                                Approve
                                                            </Button>
                                                            <Button color="warning"
                                                                type="button"
                                                                id="save"
                                                                className="save-user"
                                                                onClick={() => {
                                                                    setRejectModal(true)
                                                                    rejectModal ? inputRef.current.focus() : '';
                                                                }}
                                                            >
                                                                Reject
                                                            </Button></div>
                                                        : ""} */}

                                                    {


                                                        ((qid != null && qid != undefined && Number(qid) === 0) && (
                                                            (user.user_previleges.filter(p => Number(p.urp_mid) === constants.mainMenuList.QA && (p.urp_type === constants.menuTypes.Create && p.urp_type === constants.menuTypes.Special).length === 2))
                                                            || (user.user_previleges.filter(p =>
                                                                Number(p.urp_mid) === constants.mainMenuList.QA && (p.urp_type === constants.menuTypes.Create)).length === 1))
                                                        ) ?
                                                            <div className="text-end">

                                                                <Button color="success"
                                                                    type="submit"
                                                                    id="save"
                                                                    className="save-user"
                                                                // disabled={saveDisable === 1}
                                                                >
                                                                    Save
                                                                </Button>
                                                            </div> : ((qid != "0" && qid != null && qid != undefined && isClone === '0') &&
                                                                ((user.user_previleges.filter(p =>
                                                                    Number(p.urp_mid) === constants.mainMenuList.QA && (p.urp_type === constants.menuTypes.Modify)).length === 1)
                                                                )) && successFlag === false ?
                                                                <div className="text-end">

                                                                    <Button color="success"
                                                                        type="submit"
                                                                        id="save"
                                                                        className="save-user"
                                                                    // onClick={handleSaveClick}
                                                                    >
                                                                        Update
                                                                    </Button>
                                                                </div> : ""}

                                                    <div className="text-end">
                                                        {qid == 0 || isClone == '1' ?
                                                            <Button color="primary"
                                                                type="submit"
                                                                id="clone"
                                                                className="save-user"
                                                            // onClick={handleSaveClick}
                                                            >
                                                                Save & Continue
                                                            </Button>
                                                            : null}
                                                        {/* {qid == 0 || isClone == '1' ?
                                                            <button type="button" style={{ marginLeft: "10px" }}
                                                                onClick={handleSubmitApprove}
                                                                className="btn btn-primary">Submit for approval</button>
                                                            : null} */}

                                                        <button type="button" style={{ marginLeft: "10px" }}
                                                            onClick={handleClose}
                                                            className="btn btn-danger">Close</button>

                                                    </div>
                                                </div></Row>
                                        </CardFooter>

                                    </Card>
                                </div>
                            </Form>

                        </Col>
                    </Row>

                </Container>
            </div >
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />

            <Modal size="md" isOpen={showConfirmationModal} toggle={showConfirmationtoggle} centered={true} backdrop="static">
                <div className="modal-content">
                    <ModalBody className="px-4 py-5 text-center">
                        <p className="text-muted font-size-16 mb-4">This will clear all the data you have entered. Are you sure want to continue?</p>

                        <div className="hstack gap-2 justify-content-center mb-0">
                            <button type="button" className="btn btn-danger" onClick={clearData}>Yes</button>
                            <button type="button" className="btn btn-secondary" onClick={showConfirmationtoggle}>No</button>
                        </div>
                    </ModalBody>
                </div>
            </Modal>

            

            <Modal isOpen={rejectModal} toggle={rejectToggle} backdrop="static">
                <ModalBody toggle={rejectToggle}>
                    <Form
                        onSubmit={(e) => {
                            e.preventDefault();
                            rejectValidation.handleSubmit();
                            return false;
                        }}
                    >
                        <Row>
                            <Col className="col-12">
                                <div className="mb-3">
                                    <Label>Reason<span style={{ color: 'red' }}>*</span></Label>
                                    <Input
                                        name="reason"
                                        type="text"
                                        className="form-control"
                                        placeholder="Type here..."
                                        rows="3"
                                        autoComplete="off"
                                        maxLength={constants.textboxLength.reason}
                                        onChange={rejectValidation.handleChange}
                                        onBlur={rejectValidation.handleBlur}
                                        value={rejectValidation.values.reason || ""}
                                        innerRef={inputRef}
                                        validate={{ required: { value: true } }}
                                        invalid={rejectValidation.touched.reason && rejectValidation.errors.reason}
                                    />
                                    {rejectValidation.touched.reason && rejectValidation.errors.reason ? (
                                        <FormFeedback type="invalid">{rejectValidation.errors.reason}</FormFeedback>
                                    ) : null}
                                </div>
                            </Col>
                        </Row>
                    </Form>
                    <div className="hstack gap-2 justify-content-center mb-0">
                        <button type="button" className="btn btn-danger" onClick={rejectValidation.handleSubmit}>Reject</button>
                        <button type="button" className="btn btn-secondary" onClick={() =>
                            setRejectModal(false)
                        }>No</button>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment >
    );
};

export default AddQuestions;

